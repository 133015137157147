<template lang="html">
  <div id="services-page">

    <section class="services-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-title">
            <h1 class="title-st-1">Nuestros servicios</h1>
          </div>

          <div class="col-lg-6 col-info">
            <div class="box-icon">
              <img src="public/images/pages/services/icon-s-1.png">
            </div>

            <h3>Entrega a domicilio</h3>

            <div class="box-text">
              <p>
                Ekar de Gas cuenta con un servicio de entrega a domicilio que normalmente cuesta de entre $149.00 y $299.00 dependiendo el plazo de entrega y uno de los requisitos es que el domicilio sea dentro de la zona metropolitana de Guadalajara, y en caso de que el domicilio sea fuera de la zona, contamos con tabulador de precios y plazo de entrega. Cabe mencionar que este costo es cuando su compra es en piso de venta de alguna sucursal.
              </p>
            </div>

            <div class="box-video">
              <iframe src="https://www.youtube.com/embed/jxtuQB8g3xo?si=kYBu-nHRxMSr1QP8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>

          <div class="col-lg-6 col-info">
            <div class="box-icon">
              <img src="public/images/pages/services/icon-s-2.png">
            </div>

            <h3>Sistema de apartado</h3>

            <div class="box-text">
              <p>
                El sistema de apartado es un servicio de financiamiento donde el cliente, con un anticipo del 10% puede apartar la existencia de un determinado producto con un plazo de 30 ó 60 días. El comprador se compromete a liquidar en la fecha estipulada en el documento, de no cumplir, la mercancia quedará disponible para su venta al público y lo abonado podrá ser utilizado por otro artículo igual o de mayoy valor según disponibilidad de inventario.
              </p>
            </div>

            <div class="box-video">
              <iframe src="https://www.youtube.com/embed/AgMN9Tlqx6I?si=qM9oWWd69wg9IFgO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
