import { render, staticRenderFns } from "./category.vue?vue&type=template&id=5870c810&lang=html"
import script from "./category.vue?vue&type=script&lang=js"
export * from "./category.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports