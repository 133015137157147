<template lang="html">
  <div class="container" id="text-page">
    <h1 class="mb-3 page-title txt-black">Términos de uso</h1>

    <p>
      Al ingresar y utilizar este portal de Internet, cuyo nombre de dominio es www.ekardegas.mx, propiedad de Ekar De Gas SA de CV, que en lo sucesivo se denominará "EKAR DE GAS", el usuario está aceptando los Términos y condiciones de uso contenidos en este convenio y declara expresamente su aceptación utilizando para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo 1803 del Código Civil Federal.
    </p>

    <br />
    <p>
      En caso de no aceptar en forma absoluta y completa los términos y condiciones de este convenio, el usuario deberá abstenerse de acceder, utilizar y observar el sitio web www.ekardegas.mx, y en caso de que el usuario acceda, utilice y observe el sitio web www.ekardegas.mx, se considerará como una absoluta y expresa aceptación de los Términos y condiciones de uso aquí estipulados.
    </p>

    <br />
    <p>
      La sola utilización de dicha página de Internet le otorga al público en general la condición de usuario (en adelante referido como el «usuario» o los «usuarios») e implica la aceptación, plena e incondicional, de todas y cada una de las condiciones generales y particulares incluidas en estos Términos y condiciones de uso publicados por EKAR DE GAS en el momento mismo en que el usuario acceda al sitio web.
    </p>

    <br />
    <p>
      Cualquier modificación a los presentes Términos y condiciones de uso será realizada cuando el titular de la misma, en este caso EKAR DE GAS, lo considere apropiado, siendo exclusiva responsabilidad del usuario asegurarse de tomar conocimiento de tales modificaciones.
    </p>

    <br />
    <h5>Convenio</h5>
    <p>
      Convenio de adhesión para el uso de la página de Internet www.ekardegas.mx, que celebran: por una parte, EKAR DE GAS y, por la otra, el usuario, sujetándose, ambas partes, a lo establecido en este documento.
    </p>

    <br />
    <h5>Licencia</h5>
    <ol class="l-latin">
      <li>
        Por virtud de la celebración de este convenio, EKAR DE GAS otorga y concede al usuario el derecho no exclusivo, revocable y no transferible de ver y utilizar el sitio web www.ekardegas.mx,de conformidad con los Términos y condiciones de uso que aquí se estipulan. Para los efectos del presente convenio, las partes acuerdan que por «usuario» se entenderá a cualquier persona de cualquier naturaleza que ingrese al sitio web www.ekardegas.mx y/o a cualquiera de las subpáginas, www.ekardegas.mx, que despliegan su contenido y/o a la persona de cualquier naturaleza que se dé de alta y/o use cualquiera de los servicios que se ofrecen a través de dicha página.
      </li>
      <li>
        El usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada en el sitio web, www.ekardegas.mx, exclusivamente para uso personal, queda terminantemente prohibido el uso comercial de dicha información. En caso de ser persona moral se sujetará a lo dispuesto por el artículo 148, fracción IV de la Ley Federal del Derecho de Autor.
      </li>
      <li>
        La reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción electrónica o por otro medio, parcial o total, de cualquier información, documento o gráfico que aparezca en el sitio web, www.ekardegas.mx, para cualquier uso distinto al personal no comercial le está expresamente prohibido al usuario, a menos de que cuente con la autorización previa y por escrito de EKAR DE GAS.
      </li>
    </ol>

    <br />
    <h5>Reglas de uso del sitio</h5>
    <p>
      El usuario y EKAR DE GAS están de acuerdo en que la utilización del sitio web www.ekardegas.mx, se sujetará a las siguientes reglas:
    </p>

    <br />
    <p>
      Información contenida en el sitio web www.ekardegas.mx. El usuario reconoce y acepta que la información publicada o contenida en dicho sitio será claramente identificada de forma tal que se reconozca que la misma proviene y ha sido generada por EKAR DE GAS o por sus proveedores.
    </p>

    <br />
    <p>
      No obstante, la información, conceptos y opiniones publicadas en dicho sitio no necesariamente reflejan la posición de EKAR DE GAS, ni de sus empleados, oficiales, directores, accionistas, licenciatarios y concesionarios (en lo sucesivo los «afiliados»). Por esta razón, EKAR DE GAS no se hace responsable por ninguna de las informaciones, opiniones y conceptos que se emitan en la página web referida. En este caso, se recomienda al usuario consultar con un especialista y/o profesional en la materia. Asimismo, EKAR DE GAS no se hace responsable de la información contenida en la página de Internet, incluidas las subpáginas, en el entendido de que el uso y seguimiento de la misma es bajo riesgo y responsabilidad del usuario.
    </p>

    <br />
    <p>
      III. EKAR DE GAS se reserva el derecho de bloquear el acceso o remover en forma parcial o total toda información, comunicación o material que a su exclusivo juicio pueda resultar: i) abusivo, difamatorio u obsceno; ii) fraudulento, artificioso o engañoso; iii) violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier derecho de propiedad intelectual de un tercero; iv) ofensivo o; v) que de cualquier forma contravenga lo establecido en este convenio. Si el usuario desea obtener más información de un tema en específico proveído por EKAR DE GAS o sus proveedores deberá consultarlo directamente con cada uno de ellos, según corresponda, y/o con un especialista en la materia.
    </p>

    <br />
    <p>
      El usuario reconoce que EKAR DE GAS no controla o censura previamente el contenido disponible en la página de Internet. Por tal motivo, EKAR DE GAS no asume ninguna responsabilidad por el contenido provisto a dicha página por proveedores independientes o ajenos a EKAR DE GAS y no tiene el control editorial sobre el contenido, información y/o material generado y/o provisto por terceros. Todas las opiniones, consejos, declaraciones, servicios, ofertas u otras informaciones o contenidos expresados o puestos a disposición del público por terceros pertenecen a su respectivo autor y EKAR DE GAS no asume responsabilidad alguna frente a ello. De la misma forma, EKAR DE GAS no garantiza la exactitud, veracidad, amplitud y/o utilidad de cualquier contenido provisto por tales terceros. Adicionalmente, EKAR DE GAS no es responsable ni garantiza la exactitud, exhaustividad, veracidad y/o confiabilidad de cualquier opinión, información, consejo o declaración expresados por EKAR DE GAS a través de su página de Internet y bajo ninguna circunstancia EKAR DE GAS será responsable de cualquier daño y/o perjuicio, directo o indirecto, causado en virtud de la confianza que el usuario deposite en información obtenida a través de su sitio web. www.ekardegas.mx, se reserva el derecho de suprimir o modificar el contenido de esta página que, a exclusivo juicio de EKAR DE GAS, no cumpla con sus estándares o que pudiera resultar contrario al ordenamiento jurídico vigente y, por consiguiente, no será responsable por cualquier falla o tardanza que se genere al eliminar tal material.
    </p>

    <br />
    <h5>Formatos</h5>
    <p>
      Los usuarios reconocen que, al proporcionar la información de carácter personal requerida en alguno de los servicios que se prestan en este sitio web, otorgan a EKAR DE GAS la autorización señalada en el artículo 109 de la Ley Federal del Derecho de Autor. En todos los casos, los usuarios responderán por la veracidad de la información proporcionada a EKAR DE GAS.
    </p>

    <br />
    <p>
      Asimismo, para la prestación de servicios, el usuario se obliga además a aceptar los términos y condiciones estipulados para tal efecto.
    </p>

    <br />
    <h5>Derechos de autor</h5>
    <p>
      EKAR DE GAS, el sitio web www.ekardegas.mx, sus logotipos y todo el material que aparece en dicho sitio son marcas, nombres de dominio, nombres comerciales y obras artísticas propiedad de sus respectivos titulares y están protegidos por los tratados internacionales y las leyes aplicables en materia de propiedad intelectual y derechos de autor.<br />
      <br />
      Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, fotografías, imágenes, programas, aplicaciones, y en general cualquier información contenida o publicada en el sitio web www.ekardegas.mx, se encuentran debidamente protegidos a favor de EKAR DE GAS, sus afiliados, proveedores y/o de sus respectivos propietarios, de conformidad con la legislación aplicable en materia de propiedad intelectual e industrial.<br />
      <br />
      Se prohíbe expresamente al usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de la información contenida en el sitio señalado.<br />
      <br />
      En caso de que el usuario transmita a EKAR DE GAS cualquier información, programas, aplicaciones, software o en general cualquier material que requiera ser licenciado a través del sitio web www.ekardegas.mx, el usuario otorga con este acto a EKAR DE GAS una licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir, transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente.<br />
      <br />
      Lo establecido en el párrafo anterior se aplicará igualmente a cualquier otra información que el usuario envíe o transmita a EKAR DE GAS, incluyendo, sin limitación alguna, ideas para renovar o mejorar el sitio web www.ekardegas.mx, ya sea que éstas hayan sido incluidas en cualquier espacio de la página señalada o en virtud de otros medios o modos de transmisión conocidos o que sean desarrollados en el futuro.<br />
      <br />
      Por lo anterior, el usuario renuncia expresamente con este acto a llevar a cabo cualquier acción, demanda o reclamación en contra de EKAR DE GAS, sus afiliados o proveedores por cualquier actual o eventual violación de cualquier derecho de autor o propiedad intelectual derivado de la información, programas, aplicaciones, software, ideas y demás material que el propio usuario envíe al sitio web www.ekardegas.mx.<br />
      <br />
      Es nuestra política actuar contra las violaciones que en materia de propiedad intelectual se pudieran generar u originar según lo estipulado en la legislación y en otras leyes de propiedad intelectual aplicables, incluyendo la eliminación o el bloqueo del acceso a material que se encuentra sujeto a actividades que infrinjan el derecho de propiedad intelectual de terceros.<br />
      <br />
      En caso de que algún usuario o tercero consideren que cualquiera de los contenidos que se encuentren o sean introducidos en dicho sitio www.ekardegas.mx, y/o cualquiera de sus servicios, violen sus derechos de propiedad intelectual deberán enviar una notificación a la siguiente dirección atnclientes@ekardegas.mx, en la que indiquen: i) datos personales verídicos (nombre, dirección, número de teléfono y dirección de correo electrónico del reclamante); ii) firma autógrafa con los datos personales del titular de los derechos de propiedad intelectual; iii) indicación precisa y completa del (los) contenido (s) protegido (s) mediante los derechos de propiedad intelectual supuestamente infringidos, así como la localización de dichas violaciones en el sitio web referido; iv) declaración expresa y clara de que la introducción del (los) contenido (s) indicado (s) se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente infringidos; v) declaración expresa, clara y bajo la responsabilidad del reclamante de que la información proporcionada en la notificación es exacta y de que la introducción del(los) contenido(s) constituye una violación de dichos derechos.
    </p>

    <br />
    <h5>Material publicitario</h5>
    <p>
      El usuario reconoce y acepta que EKAR DE GAS es una organización independiente de terceros patrocinadores y anunciantes cuya información, imágenes, anuncios y demás material publicitario o promocional (en lo subsecuente «material publicitario») puede ser publicado en el sitio web www.ekardegas.mx.<br />
      <br />
      El usuario reconoce y acepta que el material publicitario no forma parte del contenido principal que se publica en dicho sitio. Asimismo, reconoce y acepta con este acto que este material se encuentra protegido por las leyes que en materia de propiedad intelectual e industrial resulten aplicables.<br />
      <br />
      Todas las promociones ofrecidas en la página tienen la vigencia exacta. Las promociones no son válidas con otras promociones ni cupones de descuento.
    </p>

    <br />
    <h5>Negación de garantías</h5>
    <p>
      El usuario está de acuerdo que la utilización del sitio web www.ekardegas.mx, se realiza bajo su propio riesgo y que los servicios y productos que ahí se prestan y ofrecen se prevén sobre una base «tal cual» y «según sean disponibles».<br />
      <br />
      EKAR DE GAS no garantiza que la página señalada satisfaga los requerimientos del usuario o que los servicios que en ella se ofrecen no sufran interrupciones, sean seguros o estén exentos de errores.EKAR DE GAS no garantiza o avala en ninguna forma la veracidad, precisión, legalidad, moralidad o ninguna otra característica del contenido del material que se publique en el sitio web www.ekardegas.mx.<br />
      <br />
      EKAR DE GAS se libera de cualquier responsabilidad y condiciones, tanto expresas como implícitas, en relación con los servicios e información contenida o disponible en o a través de esta página web; incluyendo, sin limitación alguna:
    </p>

    <br />
    <ol class="l-latin">
      <li>
        La disponibilidad de uso del sitio web www.ekardegas.mx
      </li>
      <li>
        La ausencia de virus, errores, desactivadores o cualquier otro material contaminante o con funciones destructivas en la información o programas disponibles en o a través de esta página o en general cualquier falla en dicho sitio.
      </li>
      <li>
        No obstante lo anterior, EKAR DE GAS o sus proveedores podrán actualizar el contenido de la página constantemente, por lo que se solicita al usuario tomar en cuenta que algunas informaciones publicitadas o contenidas en o a través de este sitio web pueden haber quedado obsoletas y/o contener imprecisiones o errores tipográficos u ortográficos.
      </li>
    </ol>

    <br />
    <h5>Limitaciones a la responsabilidad</h5>
    <p>
      Hasta el máximo permitido por las leyes aplicables, EKAR DE GAS no será responsable, en ningún caso, por daños directos, especiales, incidentales, indirectos, o consecuenciales que en cualquier forma se deriven o se relacionen con:
    </p>
    <ol class="l-latin">
      <li>
        El uso o ejecución del sitio web www.ekardegas.mx, con el retraso o la falta de disponibilidad de uso de EKAR DE GAS.
      </li>
      <li>
        La proveeduría o falta de la misma de servicios de cualquier información o gráficos contenidos o publicados en o a través del sitio señalado.
      </li>
      <li>
        La actualización o falta de actualización de la información.
      </li>
      <li>
        La alteración o modificación, total o parcial, de la información después de haber sido incluida en dicho sitio.
      </li>
      <li>
        Cualquier otro aspecto o característica de la información contenida o publicada en la página web o a través de las ligas que eventualmente se incluyan en este sitio.
      </li>
      <li>
        La proveeduría o falta de proveeduría que los demás servicios, todos los supuestos anteriores serán vigentes, aún en los casos en que se le hubiere notificado o avisado a EKAR DE GAS acerca de la posibilidad de que se ocasionarán dichos daños.
      </li>
    </ol>

    <br />
    <h5>Modificaciones al sitio web www.ekardegas.mx</h5>
    <p>
      EKAR DE GAS podrá en cualquier momento y cuando lo considere conveniente, sin necesidad de avisar al usuario, realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, servicios, áreas, bases de datos y demás elementos de dicho sitio, sin que ello de lugar ni derecho a ninguna reclamación o indemnización, ni que esto implique reconocimiento de responsabilidad alguna a favor del usuario.
    </p>

    <br />
    <h5>Modificaciones al convenio</h5>
    <p>
      EKAR DE GAS se reserva el derecho de modificar los Términos y condiciones de uso de este convenio en cualquier momento, siendo efectivas dichas modificaciones de forma inmediata por medio de:
    </p>
    <ol class="l-latin">
      <li>
        La publicación en el sitio web www.ekardegas.mx,del convenio modificado.
      </li>
      <li>
        La notificación al usuario sobre dichas modificaciones.
      </li>
    </ol>
    <p>
      De esta forma, el usuario está de acuerdo en revisar dicho convenio periódicamente con la finalidad de mantenerse al tanto de dichas modificaciones. No obstante, lo anterior, cada vez que el usuario acceda al sitio señalado se considerará como una aceptación absoluta a las modificaciones del presente convenio.
    </p>

    <br />
    <h5>Términos adicionales</h5>
    <p>
      Ocasionalmente, EKAR DE GAS podrá agregar a los Términos y condiciones de uso del presente convenio provisiones adicionales relativas a áreas específicas o nuevos servicios que se proporcionen en o a través del sitio web www.ekardegas.mx, (en lo subsecuente «términos adicionales»), los cuales serán publicados en las áreas específicas o nuevos servicios de dicho sitio para su lectura y aceptación. El usuario reconoce y acepta que estos términos adicionales forman parte integrante del presente  convenio para todos los efectos legales a que haya lugar.
    </p>

    <br />
    <h5>Cesión de derechos</h5>
    <p>
      EKAR DE GAS podrá, en cualquier momento y cuando así lo estime conveniente, ceder total o parcialmente sus derechos y obligaciones derivados del presente convenio. En virtud de dicha cesión, EKAR DE GAS quedará liberada de cualquier obligación a favor del usuario, establecida en el presente convenio.
    </p>

    <br />
    <h5>Cesión de derechos</h5>
    <p>
      EKAR DE GAS podrá, en cualquier momento y cuando así lo estime conveniente, ceder total o parcialmente sus derechos y obligaciones derivados del presente convenio. En virtud de dicha cesión, EKAR DE GAS quedará liberada de cualquier obligación a favor del usuario, establecida en el presente convenio.
    </p>

    <br />
    <h5>Indemnización</h5>
    <p>
      El usuario está de acuerdo en indemnizar a EKAR DE GAS, sus afiliados, proveedores, vendedores y asesores por cualquier acción, demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de cualquier incumplimiento por parte del usuario al presente convenio; incluyendo, sin limitación de alguna de las derivadas de:
    </p>
    <ol class="l-latin">
      <li>
        Cualquier aspecto relativo al uso del sitio web www.ekardegas.mx.
      </li>
      <li>
        La información contenida o disponible en o a través de dicho sitio o de injurias, difamación o cualquier otra conducta violatoria del presente convenio por parte del usuario en el uso de la página web señalada.
      </li>
      <li>
        La violación a las leyes aplicables o tratados internacionales relativos a los derechos de autor o propiedad intelectual, contenidos o disponibles en, o a través de dicho sitio web.
      </li>
    </ol>

    <br />
    <h5>Terminación</h5>
    <p>
      EKAR DE GAS se reserva el derecho, a su exclusiva discreción, y sin necesidad de aviso o notificación al usuario, para:
    </p>

    <ol class="l-latin">
      <li>
        Terminar definitivamente el presente convenio.
      </li>
      <li>
        Descontinuar o dejar de publicar definitivamente el sitio web www.ekardegas.mx, sin responsabilidad alguna para EKAR DE GAS, sus afiliados o proveedores.
      </li>
    </ol>

    <br />
    <h5>Subsistencia</h5>
    <p>
      Estos Términos y condiciones de uso, así como los términos adicionales, constituyen el acuerdo íntegro entre las partes, y sustituye cualquier otro acuerdo o convenio celebrado con anterioridad. Cualquier cláusula o provisión del presente convenio, así como de los términos adicionales, legalmente declarada inválida, será eliminada o modificada a elección de EKAR DE GAS, con la finalidad de corregir su vicio o defecto. Sin embargo, el resto de las cláusulas o provisiones mantendrán su fuerza, obligatoriedad y validez.
    </p>

    <br />
    <h5>No renuncia de derechos</h5>
    <p>
      La inactividad por parte de EKAR DE GAS, sus afiliados o proveedores al ejercicio de cualquier derecho o acción derivados del presente convenio, en ningún momento deberá interpretarse como renuncia a dichos derechos o acciones.
    </p>

    <br />
    <h5>Legislación aplicable y jurisdicción</h5>
    <p>
      Este convenio estará sujeto y será interpretado de acuerdo con las leyes y ante los tribunales del Distrito Federal, México.<br />
      <br />
      Términos y condiciones de uso vigentes desde el 1º de julio del 2019.<br />
      <br />
      Por la presente, una vez leído el presente términos y condiciones de uso del sitio web www.ekardegas, propiedad de Ekar De Gas SA de CV, entendiendo todos los términos y condiciones, otorgo mi consentimiento al estar de acuerdo con los términos del presente.
    </p>
  </div>
</template>

<script>
export default {
}
</script>
