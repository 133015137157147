<template>
	<footer id="footer">

		<section class="newsletter-section">
			<div class="container oversized-container">
				<div class="row align-items-center row-top">
	        <div class="col-lg-4 col-left col-newsletter">
	          <b-form @submit="onSubmit">
	            <b-form-input
	              v-model="form.email"
	              type="email"
	              placeholder="Escribe tu correo..."
	              required
	            ></b-form-input>

	            <b-button type="submit" class="btn-submit">Inscribirse</b-button>
	          </b-form>
	        </div>

	        <div class="col-lg col-middle">
	          <h6>Sé el primero en enterarte</h6>
	          <h4>Regístrate a nuestro newsletter</h4>
	        </div>

	        <div class="col-lg row mx-0 col-right">
	          <div class="box-networks">
							<a class="t-150 btn-network fb" target="_blank" href="https://www.facebook.com/EkarDeGas"><i class="fab fa-facebook-f"></i></a>
							<a class="t-150 btn-network lk" target="_blank" href="https://www.linkedin.com/"><i class="fab fa-linkedin-in"></i></a>
							<a class="t-150 btn-network tw" target="_blank" href="https://twitter.com/"><i class="fa-brands fa-x-twitter"></i></a>
							<a class="t-150 btn-network ig" target="_blank" href="https://www.instagram.com/ekardegas/"><i class="fab fa-instagram"></i></a>
	            <!-- <a class="t-150 btn-network ig" target="_blank" href="https://clic2.chat/f0d0b7"><i class="fa-brands fa-whatsapp"></i></a> -->
	          </div>
	          <router-link class="logo" to="/"><img src="public/images/logo.png"></router-link>
	        </div>
	      </div>
			</div>
		</section>

		<section class="menu-section">
			<div class="container oversized-container">
				<div class="row">
					<div class="col-sm-6 col-lg-4 col-menu">
						<h5 class="title">Atención a clientes</h5>

						<p>
							<router-link to="/login" v-if="!$root.logged">Mi cuenta</router-link>
							<router-link to="/usuario" v-if="$root.logged">Mi cuenta</router-link>
						</p>
						<p>
							<router-link to="/mis-favoritos">Mis favoritos</router-link>
						</p>
						<p>
							<router-link to="/servicios">Sistema de apartado</router-link>
						</p>
						<p>
							<router-link to="/servicios">Entrega a domicilio</router-link>
						</p>
						<p>
							<router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
						</p>
						<p>
							<router-link to="/terminos-de-uso">Términos de uso</router-link>
						</p>
						<p>
							<router-link to="/politicas-de-envio">Políticas de envío</router-link>
						</p>
						<p>
							<router-link to="/bolsa-de-trabajo">Bolsa de trabajo</router-link>
						</p>
					</div>

					<div class="col-sm-6 col-lg-4 col-menu">
						<h5 class="title">SITIO WEB</h5>

						<p>
							<router-link to="/sucursales">Sucursales</router-link>
						</p>
						<p>
							<router-link to="/servicios">Servicios</router-link>
						</p>
						<p>
							<router-link to="/nosotros">Nosotros</router-link>
						</p>

						<!-- IMPORTANTE!!! NO AGREGAR MÁS DE 6 CATEGORÍAS en esta lista -->
						<p>
							<router-link to="/categoria/ca-2">Smart Home</router-link>
						</p>
						<p>
							<router-link to="/categoria/ca-2">Colchones</router-link>
						</p>
						<p>
							<router-link to="/categoria/ca-2">Refrigeradores</router-link>
						</p>
						<p>
							<router-link to="/categoria/ca-2">Instalaciones de gas</router-link>
						</p>
						<p>
							<router-link to="/categoria/ca-2">Lavadoras</router-link>
						</p>
					</div>

					<div class="col-lg-4 col-menu">
						<h5 class="title">Contacto</h5>

						<p class="mb-1">
							<i class="fa-solid fa-square-phone ic-phone"></i> 33 3345 5098
						</p>
						<p class="mb-1">
							<i class="fa-solid fa-square-phone ic-phone"></i> 33 3345 5099
						</p>
						<p>
							<a href="mailto:atención@ekardegas.mx">
								<i class="fa-solid fa-envelope"></i> atención@ekardegas.mx
							</a>
						</p>

						<div class="box-address">
							<p>
								Calz. González Gallo 496, Col. San Carlos<br />
								C.P. 44460, Guadalajara, Jalisco.
							</p>
						</div>

						<div class="box-payments">
							<img src="public/images/shared/mercadopago.webp">
							<img src="public/images/shared/mastercard.svg">
							<img class="visa" src="public/images/shared/visa.svg">
							<img src="public/images/shared/paypal.png">
						</div>
					</div>

					<div class="col-12 col-copy">
						<hr />
						<p>
							<span class="txt">Ekar de Gas S.A. de C.V. ® {{ currentYear }}</span>
							<span class="l-mid">|</span>
							<span class="txt">Todos los derechos reservados</span>
						</p>
					</div>
				</div>
			</div>
		</section>

	</footer>
</template>

<script type="text/javascript">
export default {
	data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },
      info: {
        email:null,
        contact:null,
        address:null,
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
  },

  beforeMount(){
    this.currentYear = new Date().getFullYear();
  }
}
</script>
