<template lang="html">
  <div id="dealers-page">
    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center pb-1">
            <h2 class="title-st-1">Sucursales</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-dealers">
          <a class="btn btn-dealer" v-bind:class="{ active: distribuidores[dInx].active == true }" v-for="(d, dInx) in distribuidores" :key="'dInx-'+dInx" @click="selectDealer(dInx)">{{ d.name }}</a>
        </div>

        <div class="col-lg-9 col-dealer-info">
          <a id="anchor-dinf"></a>

          <div class="row">
            <div class="col-md col-map">
              <div class="box-map">
                <iframe :src="dealerInfo.mapUrl" width="100%" height="600" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
              </div>
            </div>

            <div class="col-md col-information">
              <div class="box-info">
                <div class="placed-backg b-image" v-bind:style="{ backgroundImage: 'url('+dealerInfo.image+')' }"></div>

                <div class="b-text">
                  <h5 class="mb-2 name">{{ dealerInfo.name }}</h5>

                  <h6 class="label">Dirección:</h6>
                  <p class="mb-2" v-html="dealerInfo.address"></p>

                  <h6 class="label">Horario:</h6>
                  <p class="mb-2" v-html="dealerInfo.schedules"></p>

                  <h6 class="label">Teléfono:</h6>
                  <p>
                    <a :href="'tel:'+removeSpaces(dealerInfo.phone)">{{ dealerInfo.phone }}</a>
                  </p>

                  <p class="mt-3">
                    <a class="btn-map t-150" target="_blank" :href="dealerInfo.btnUrl">Mostrar en mapa</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      dealerInfo: {
        id: null,
        name: null,
        email: null,
        phone: null,
        address: null,
        mapUrl: null,
        btnUrl: null,
        image_id: null,
        image: null,
        active: false,
      },

      distribuidores: [
        {
          active: false,
          "id": 1,
          "name": "El Salto",
          "schedules": "Lunes a domingo de 10:00 a 20:00",
          "phone": "33 3619 4155",
          "address": `Los Angeles No.92<br />Sector Reforma C.P. 44460<br />Guadalajara, Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14932.61116238492!2d-103.346026!3d20.663362!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b21ec5a77e5b%3A0x48ca17f39fd65003!2sEkar%20de%20Gas%20El%20Salto!5e0!3m2!1ses-419!2smx!4v1729092530380!5m2!1ses-419!2smx",
          "btnUrl": "",
          "image": "public/images/pages/branches/ekar-1.jpg",
        },
        {
          active: false,
          "id": 2,
          "name": "Tercer Salto",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 16:00 ",
          "phone": "33 3619 3591",
          "address": `Av. Gonzalez Gallo No. 496<br />Col. San Carlos C.P. 44460<br />Guadalajara Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14933.457966239343!2d-103.340676!3d20.654745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b219fc8531bf%3A0xd436f09ed33e6527!2sEkar%20de%20Gas%20Tercer%20Salto!5e0!3m2!1ses-419!2smx!4v1729098584682!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.654745,-103.340676&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15291674149722678567",
          "image": "public/images/pages/branches/ekar-2.jpg",
        },
        {
          active: false,
          "id": 3,
          "name": "Gran Salto",
          "schedules": "Lunes a sabado de 10:00 a 20:30<br />Domingo de 10:00 a 17:00 ",
          "phone": " 33 3629 9129",
          "address": `Av. Vallarta No.4820 local 1<br />Col. Jardines Vallarta<br />C.P. 45020 Zapopan, Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14931.115336483323!2d-103.418254!3d20.678575!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae923cd8192d%3A0x16ef6d0b7ceeb82b!2sEkar%20de%20Gas%20Gran%20Salto!5e0!3m2!1ses-419!2smx!4v1729102351963!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.678575,-103.418254&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=1652659484376348715",
          "image": "public/images/pages/branches/ekar-3.jpg",
        },
        {
          active: false,
          "id": 4,
          "name": "Salto San Juan Bosco",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00 ",
          "phone": " 33 2153 4078",
          "address": `Industria No.1543 Sector Libertad<br />C.P. 44380 Zona Olimpica<br />Guadalajara Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14931.39682446824!2d-103.31383000000001!3d20.675713000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b18241b61dbf%3A0x20cdd95608b0531!2sEkar%20de%20Gas%20Salto%20San%20Juan%20Bosco!5e0!3m2!1ses-419!2smx!4v1729103168709!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.675713,-103.31383&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=147736521435972913",
          "image": "public/images/pages/branches/ekar-4.jpg",
        },
        {
          active: false,
          "id": 5,
          "name": "Salto Olímpico",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00 ",
          "phone": " 33 3618 6332",
          "address": `Calz. Independencia Nte. No.757<br />Col. Independencia C.P. 44920<br />Guadalajara Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14930.087717117072!2d-103.335075!3d20.68902!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b19548c0718d%3A0x62fab7b05384b4b4!2sEkar%20de%20Gas%20Salto%20Ol%C3%ADmpico!5e0!3m2!1ses-419!2smx!4v1729103409005!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.68902,-103.335075&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=7132214927837934772",
          "image": "public/images/pages/branches/ekar-5.jpg",
        },
        {
          active: false,
          "id": 6,
          "name": "Salto Seguro",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00 ",
          "phone": " 33 3811 7992",
          "address": `Av.8 de Julio No.1896<br />Plaza las Torres<br />Zona B local 5 y 6<br />C.P.44920 Guadalajara Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14934.115138902258!2d-103.360877!3d20.6480553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b20a30000001%3A0x94fd432c107d45fc!2sEkar%20de%20Gas%20Salto%20Seguro!5e0!3m2!1ses-419!2smx!4v1729105024989!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.648146,-103.361119&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=10735810943255397884",
          "image": "public/images/pages/branches/ekar-6.jpg",
        },
        {
          active: false,
          "id": 7,
          "name": "Salto Águilas",
          "schedules": "Lunes a sabado de 10:00 a 20:30<br />Domingo de 10:00 a 17:00 ",
          "phone": " 33 3632 8106",
          "address": `Av. Lopez Mateos Sur No.5468 local 10 C.P.45080 Zapopan Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14936.107730352507!2d-103.418993!3d20.627759!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ac45a7f0d66d%3A0xf2c091f019f67e4e!2sEkar%20de%20Gas%20Salto%20las%20Aguilas!5e0!3m2!1ses-419!2smx!4v1729105198584!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.627759,-103.418993&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=17492141413120769614",
          "image": "public/images/pages/branches/ekar-7.jpg",
        },
        {
          active: false,
          "id": 8,
          "name": "Salto San Isídro",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00",
          "phone": " 33 3656 4457",
          "address": `Periferico Nte. No.221 local e-17<br />Plaza San Isídro Col. Industral los Belenes<br />C.P. 45184 Zapopan Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14924.905580720755!2d-103.385228!3d20.741616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af93aae3f90b%3A0x76ce72cd2044f789!2sEkar%20de%20Gas%20Salto%20San%20Isidro!5e0!3m2!1ses-419!2smx!4v1729106183760!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.741616,-103.385228&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=8560906167013144457",
          "image": "public/images/pages/branches/ekar-8.jpg",
        },
        {
          active: false,
          "id": 9,
          "name": "Salto del Sol",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00",
          "phone": " 33 3123 9201",
          "address": `Av. Mariano Otero No.2795<br />Fracc. Residencial Victoria<br />Zapopan Jal. C.P.45089`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14933.951820098035!2d-103.400161!3d20.649718000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ab8dc5d4a7e1%3A0x9f951f028f152b7!2sEkar%20de%20Gas%20Salto%20del%20Sol!5e0!3m2!1ses-419!2smx!4v1729107490151!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.649718,-103.400161&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=718695707463209655",
          "image": "public/images/pages/branches/ekar-9.jpg",
        },
        {
          active: false,
          "id": 10,
          "name": "Salto de la Consti",
          "schedules": "Lunes a sabado de 10:00 a 20:30<br />Domingo de 10:00 a 15:00",
          "phone": "33 3660 9341",
          "address": `Manuel M. Dieguez No.377<br />Col. Constitucion C.P. 45180<br />Zapopan Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14926.357168616547!2d-103.368503!3d20.726896!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428afdb6c95811b%3A0xb73966c3f4a1a67d!2sEkar%20de%20Gas%20Salto%20de%20la%20Consti!5e0!3m2!1ses-419!2smx!4v1729108069968!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.726896,-103.368503&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=13202696774422210173",
          "image": "public/images/pages/branches/ekar-10.jpg",
        },
        {
          active: false,
          "id": 11,
          "name": "Salto del Valle",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00 ",
          "phone": " 33 1198 2807",
          "address": `Camino a la Concha No. 6700-4<br />Col. Fracc. el Paraiso 1era Etapa<br />C.P.45640 Tlajomulco de Zuñiga, Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14944.511225032616!2d-103.360556!3d20.541951!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f529e75af09e5%3A0x55173a489e93c8c9!2sEkar%20de%20Gas%20Salto%20del%20Valle!5e0!3m2!1ses-419!2smx!4v1729108451843!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.541951,-103.360556&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=6131433501260761289",
          "image": "public/images/pages/branches/ekar-11.jpg",
        },
        {
          active: false,
          "id": 12,
          "name": "Salto Tránsito",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00",
          "phone": " 33 3824 6757",
          "address": `Av. Circunvalacion No.1023<br />Col. Santa Elena Alcalde<br />CP.44220 Guadalajara , Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14928.559390943392!2d-103.347628!3d20.704545000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b1d1da3a57f9%3A0x13f56beb265a3fd6!2sEkar%20de%20Gas%20Salto%20Tr%C3%A1nsito!5e0!3m2!1ses-419!2smx!4v1729110072498!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.704545,-103.347628&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=1438174313719676886",
          "image": "public/images/pages/branches/ekar-12.jpg",
        },
        {
          active: false,
          "id": 13,
          "name": "Salto Real",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00",
          "phone": " 33 3839 1122",
          "address": `Camino Real de Colima No.423<br />Int. a2b ya2c col. Los Gavilanes<br />C.P.45645 Tlajomulco de Zuñiga`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14941.75003284872!2d-103.44045248080818!3d20.570183276123718!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428acadfe61ac17%3A0x936bd70c9650a749!2sEkar%20de%20Gas%20Salto%20Real!5e0!3m2!1ses-419!2smx!4v1729110654326!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.570183,-103.440452&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=10622820595144894281",
          "image": "public/images/pages/branches/ekar-13.jpg",
        },
        {
          active: false,
          "id": 14,
          "name": "Salto Tateposco",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 17:00 ",
          "phone": " 33 1656 9639",
          "address": `Carretera Libre Zapotlanejo No.3617<br />Plaza Arrayanes C.P.45630<br />Tateposco Tonalá , Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14937.838016917223!2d-103.274071!3d20.610119000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ada8c97eaca3%3A0xdd9cd05c8ba16c9b!2sEkar%20de%20Gas%20Salto%20Tateposco!5e0!3m2!1ses-419!2smx!4v1729110789392!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.610119,-103.274071&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=15968867474647116955",
          "image": "public/images/pages/branches/ekar-14.jpg",
        },
        {
          active: false,
          "id": 15,
          "name": "Salto Tlaquepaque",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 17:00",
          "phone": " 33 1653 9340",
          "address": `Francisco Silva Romero No.112<br />San Pedro Tlaquepaque C.P. 45519<br />Guadalajara, Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d18280.833224791357!2d-103.30329530075696!3d20.642425276544074!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b21ecfffffff%3A0x457bc78d6bf410d6!2sEkar%20de%20Gas%20Salto%20Tlaquepaque!5e0!3m2!1ses-419!2smx!4v1729111976778!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.641395,-103.301978&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=5006814820967911638",
          "image": "public/images/pages/branches/ekar-15.jpg",
        },
        {
          active: false,
          "id": 16,
          "name": "Salto Aztlán",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 18:00",
          "phone": " 33 1652 2834",
          "address": `Av.Tonala No.1430 local 1<br />Plaza Box Market Tonalá<br />C.P. 45402 Tonalá, Jal.`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14937.174613507696!2d-103.279!3d20.616884!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b31f448a62b1%3A0xedc744c4216f547c!2sEkar%20de%20Gas%20Salto%20Aztl%C3%A1n!5e0!3m2!1ses-419!2smx!4v1729112301500!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.616884,-103.279&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=17133738916519564412",
          "image": "public/images/pages/branches/ekar-16.jpg",
        },
        {
          active: false,
          "id": 17,
          "name": "Salto Santa Fé",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 17:00",
          "phone": "33 3366 9633",
          "address": `Av. Concepción del Valle No. 108<br />Col. San José del Valle<br />Tlajomulco de Zúñiga Jal. C.P.45654`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14945.732013521745!2d-103.376296!3d20.529457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f5387576c0451%3A0xe7d8891a34056fb1!2sEkar%20de%20Gas%20Salto%20Santa%20F%C3%A9!5e0!3m2!1ses-419!2smx!4v1729112715328!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.529457,-103.376296&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=16706253563365781425",
          "image": "public/images/pages/branches/ekar-17.jpg",
        },
        {
          active: false,
          "id": 18,
          "name": "Salto Oblatos",
          "schedules": "Lunes a sabado de 10:00 a 20:00<br />Domingo de 10:00 a 17:00",
          "phone": "33 3699 6246",
          "address": `calle Hacienda de Santiago No.2306<br />Col. Oblatos C.P.44320<br />Guadalajara, Jal`,
          "mapUrl": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14929.538728731175!2d-103.297303!3d20.694598!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b19133e51a8b%3A0xa76929e16263986c!2sEkar%20de%20Gas%20Salto%20Oblatos!5e0!3m2!1ses-419!2smx!4v1729112996459!5m2!1ses-419!2smx",
          "btnUrl": "https://maps.google.com/maps?ll=20.694598,-103.297303&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=12063219124883658860",
          "image": "public/images/pages/branches/ekar-18.jpg",
        },
      ],
    }
  },

  methods: {
    removeSpaces(val) {
      return val.replace(/\s/g, '');
    },

    selectDealer(d) {
      let windowW = window.innerWidth;
      this.dealerInfo = this.distribuidores[d];

      for (var i = 0; i < this.distribuidores.length; i++) {
        this.distribuidores[i].active = false;
      }

      this.distribuidores[d].active = true;

      document.querySelector('#anchor-dinf').scrollIntoView({
        behavior: 'smooth'
      });
      // if(windowW <= 767) {}
    },

    getDealers: function(){
        axios.get(tools.url('/dealersFront')).then(
          ({data}) =>{
            this.distribuidores=data;
            this.distribuidores[0].id_tab = "v-pills-map"+ this.distribuidores[0].id;
              this.distribuidores[0].id_tab_content= "v-pills-map"+ this.distribuidores[0].id+"-tab";
              this.distribuidores[0].tabClass= "tab-pane fade show active";
              this.distribuidores[0].btnText= "Ver en Maps";

            for (var i = 1; i <= this.distribuidores.length; i++) {
              this.distribuidores[i].id_tab = "v-pills-map"+ this.distribuidores[i].id;
              this.distribuidores[i].id_tab_content= "v-pills-map"+ this.distribuidores[i].id+"-tab";
              this.distribuidores[i].tabClass= "tab-pane fade";
              this.distribuidores[i].btnText= "Ver en Maps";
            }
          });
    },
  },

  mounted() {
    // this.getDealers();

    this.distribuidores[0].active = true;
    this.dealerInfo = this.distribuidores[0];
  }
};
</script>
