<template lang="html">
  <div id="jobs-page">

    <section class="container main-section">
      <h2 class="title-st-1">Bolsa de trabajo</h2>

      <div class="row results-container">
        <div class="col-12 col-jobs-s2" v-for="(job, hjbInx) in jobs" :key="'hjbInx-'+hjbInx">
          <router-link class="box" to="/bolsa-de-trabajo/1">
            <div class="col col-info">
              <h4 class="title">{{ job.title }}</h4>
              <h5><span class="department">{{ job.department }}</span> <span class="location">Guadalajara, Jalisco.</span></h5>
              <h5 class="extra">Publicado hace 39 días.</h5>
            </div>

            <div class="col-12 col-line">
              <hr />
            </div>

            <div class="col col-salary">
              <p>$7,500 al Mes</p>
            </div>
          </router-link>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      jobs: [
        { title: 'Vendedor/almacenista sucursal Plaza del Sol', department: 'Ventas' },
        { title: 'Vendedor/almacenista sucursal Col. Constitucion', department: 'Ventas' },
        { title: 'Vendedor de piso sucursal Plaza San Isidro', department: 'Ventas' },
        { title: 'Vendedor de piso sucursal Av. Vallarta', department: 'Ventas' },
        { title: 'Vendedor de piso Col. Las Aguilas', department: 'Ventas' },
      ],
    }
  }
}
</script>
