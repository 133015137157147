<template lang="html">
  <div id="cart-page" class="d-flex account-page">
    <div class="container main-box">

      <div class="row">
        <div class="col-12 px-2">
          <h1 class="mb-3 page-title">Mi carrito</h1>
        </div>

        <div class="col-lg px-2 col-menu">
          <div class="white-box">
            <div class="box-menu py-2">
              <p class="item">
                <router-link class="btn-menu" to="/cart"><i class="fas fa-shopping-cart"></i> Mi carrito</router-link>
              </p>
              <p class="item">
                <router-link class="btn-menu" to="/mis-favoritos"><i class="fas fa-heart"></i> Mis favoritos</router-link>
              </p>
              <!-- <p class="item">
                <router-link class="btn-menu" to="/historial-de-compras"><i class="fas fa-history"></i> Historial de compras</router-link>
              </p> -->
            </div>
          </div>
        </div>

        <div class="col-lg px-2 col-cart-data">

          <div class="white-box">

            <!-- Paso 1 (Lista de productos agregados a carrito) -->
            <div class="col-12 pt-3 product-list-table" v-if="$route.params.step !== 'envio' && $route.params.step !== 'pago' && $route.params.step !== 'finalizado'">
              <div class="col-12 px-0">
                <h5>Productos agregados al carrito</h5>

                <h6 class="mt-4 pb-4 f-w-400 text-warning" v-if="!form.products.length"><i class="fas fa-shopping-basket"></i> Ningun producto ha sido agregado al carrito</h6>
              </div>

              <div class="row row-products" v-for="(product, index) in form.products">
                <div class="col col-image">
                  <img :src="product.imageUrl">
                </div>

                <div class="col col-info">
                  <h5 class="mb-1 txt-name">
                    <router-link target="_blank" :to="'/producto/'+product.id">{{ product.name }}</router-link>
                  </h5>

                  <div class="row mx-0 mb-1 align-items-center">
                    <div class="d-flex">
                      <h6 class="txt-sm text-muted mr-2">SKU: {{ product.sku }}</h6>
                      <h6 class="txt-sm text-muted mr-2" v-if="product.color"> Color: {{ product.color }} </h6>
                      <h6 class="txt-sm text-muted" v-if="product.size"> Talla: {{ product.size }} </h6>
                    </div>
                    <!-- <div class="d-flex">
                      <div class="box-color-opt opt-sm">
                        <a class="color" title="Color x" v-bind:style="{ backgroundColor: '#e63003' }">{{ product.color }}</a>
                        <a class="color" :title="product.color">{{ product.color }}</a>
                        <a class="color" :title="product.size">{{ product.size }}</a>
                      </div>
                    </div> -->
                  </div>

                  <div class="box-shipping-type">
                    <span class="icon i-1"></span>
                    <span class="text">Llega mañana</span>
                    <!-- <span class="icon i-2"></span>
                    <span class="text">Llega en 4 días</span> -->
                    <!-- <span class="icon i-3"></span>
                    <span class="text">Llega en 1 semana</span> -->
                  </div>

                  <hr class="mt-0 mb-1" />

                  <router-link class="link-sm text-muted" target="_blank" :to="'/producto/'+product.id">Ver producto</router-link> <span class="mx-2">|</span> <a class="link-sm text-danger" @click="removeCart(index)"><i class="far fa-trash-alt"></i> Quitar</a>
                </div>

                <div class="col col-price">
                  <p>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} MXN</p>
                </div>
                <div class="col col-num">
                  <b-form-input v-model="product.quantity" type="number" min="1" :max="product.stock" class="text-center" placeholder="0" />
                </div>
              </div>
            </div>
            <!-- paso 1, Fin -->

            <!-- Paso 2 (Envio, Datos del usuario y datos de envío) -->
            <form id="form-cliente" class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'envio'" @submit.prevent="validar()">
            <!-- <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'envio'"> -->
              <h5 class="f-w-700">Datos personales</h5>
              <hr class="c-hr" />

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Nombre:</label>
                <p class="txt-simple" v-if="$root.logged">{{ form.user.name }}</p>
                <b-form-input id="user-name" v-else type="text" v-model="form.user.name" size="sm" required placeholder="" />
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Apellidos:</label>
                <p class="txt-simple" v-if="$root.logged" v-text="form.user.lastname"></p>
                <b-form-input id="user-lastname" v-else type="text" v-model="form.user.lastname" size="sm" required placeholder="" />
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Correo electrónico:</label>
                <p class="txt-simple" v-if="$root.logged" v-text="form.user.email"></p>
                <b-form-input id="user-email" v-else type="email" v-model="form.user.email" size="sm" required placeholder="" />
              </div>

              <div class="custom-input _p">
                <label class="col-form-label pt-0">Teléfono:</label>
                <b-form-input id="user-phone" type="text" v-model="form.user.phone" size="sm" required placeholder="" maxlength="10" />
              </div>

              <div class="row mx-0 no-gutters">
                <div class="col-md-6 pr-md-3">
                  <b-form-group class="custom-input" label="Estado:" label-for="i-estado">
                      <b-form-select id="festado" v-model="form.user.state_id" :options="states"  value-field="id" text-field="name" size="sm" required></b-form-select>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group class="custom-input" label="Ciudad:" label-for="i-ciudad">
                      <b-form-select id="festado" v-model="form.user.town_id" :options="towns"  value-field="id" text-field="name" size="sm" required></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <h5 class="mt-5 f-w-700">Formas de entrega</h5>
              <hr class="c-hr" />

              <div class="row pt-2 justify-content-center shipping-options-container">
                <div class="col col-box" @click="changeShipping('entregaInstalacion')">
                  <div class="box" v-bind:class="{ active: form.tipoEntrega == 'entregaInstalacion' }">
                    <h6>Entrega y acomodo</h6>
                    <div class="b-txt-icon">
                      <div class="col col-icons">
                        <img src="public/images/pages/products/shipping-a.svg">
                      </div>
                      <div class="col col-descr">
                        <p>
                          En mi domicilio.<br />
                          De 4 a 6 días habiles.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col col-box" @click="changeShipping('entrega')">
                  <div class="box" v-bind:class="{ active: form.tipoEntrega == 'entrega' }">
                    <h6>Entrega</h6>
                    <div class="b-txt-icon">
                      <div class="col col-icons">
                        <img src="public/images/pages/products/shipping-b.svg">
                      </div>
                      <div class="col col-descr">
                        <p>
                          En mi domicilio.<br />
                          De 4 a 6 días habiles.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col col-box" @click="changeShipping('recoger')">
                  <div class="box" v-bind:class="{ active: form.tipoEntrega == 'recoger' }">
                    <h6>Recolectar en tienda</h6>
                    <div class="b-txt-icon">
                      <div class="col col-icons">
                        <img src="public/images/pages/products/shipping-c.svg">
                      </div>
                      <div class="col col-descr">
                        <p>
                          2 días hábiles.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box-delivery-info" v-if="form.tipoEntrega">
                <b-card no-body>
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <a v-b-toggle.accordion-1>Caracteristicas y politicas de entrega</a>
                  </b-card-header>
                  <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div v-if="form.tipoEntrega == 'entregaInstalacion'">
                        <p class="mb-2">
                          De 4 a 6 dias habiles.<br />
                          Entregamos el producto en el domicilio que indicaste, lo acomodamos donde lo necesites ( segunda planta , cuarto de servicio etc… ), abrimos, y nos llevamos todo el empaque.<br />
                        </p>
                        <p>
                          <i class="fa-regular fa-triangle-exclamation"></i> Esto no incluye instalacion.
                        </p>
                      </div>
                      <div v-if="form.tipoEntrega == 'entrega'">
                        <p class="mb-2">
                          De 4 a 6 dias habiles.
                        </p>
                        <p>
                          Entregamos el producto en la puerta del domicilio indicado.
                        </p>
                      </div>
                      <div v-if="form.tipoEntrega == 'recoger'">
                        <p>
                          Recolectas en la sucursal de tu elección con tu transporte, uno de nuestros vendedores estará esperándote para entregarte y asesorarte.
                        </p>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>

              <h6 class="mt-3 f-w-700" v-if="form.tipoEntrega == 'entregaInstalacion'">Información para entrega y acomodo</h6>
              <h6 class="mt-3 f-w-700" v-if="form.tipoEntrega == 'entrega'">Información para entrega</h6>
              <h6 class="mt-3 f-w-700" v-if="form.tipoEntrega == 'recoger'">Información para recolectar en tienda</h6>
              <hr class="c-hr" />

              <div v-if="form.tipoEntrega == 'entregaInstalacion' || form.tipoEntrega == 'entrega'">
                <div class="row">
                  <div class="col-12">
                    <b-form-group class="custom-input" label="Dirección completa:">
                      <b-form-input type="text" v-model="form.user.entregaDireccion" size="sm" placeholder="Calle o avenida, numero y colonia" required />
                    </b-form-group>
                  </div>

                  <div class="col-12 box-map">
                    <GmapMap
                      :center="{lat:20.6777284, lng:-103.3802309}"
                      :zoom="12.0">
                      <GmapMarker
                        :position="{lat:20.6777284, lng:-103.3802309}"
                        :clickable="true"
                        :draggable="true"
                      />
                    </GmapMap>
                  </div>

                  <div class="col-12 box-dropdown-info">
                    <h5 v-b-toggle.collapse-3><span>Más especificaciones para la entrega</span></h5>

                    <!-- <b-button  class="m-1">Toggle Collapse</b-button> -->
                    <b-collapse class="box-address-info" id="collapse-3">
                      <b-card>
                        <div class="row">
                          <div class="col-12 mb-2">
                            <h6 class="f-w-700">Contacto de quien recolecta</h6>
                            <p>
                              Si desea que alguien mas reciba su pedido, agreguelo aquí.
                            </p>
                          </div>

                          <div class="col-md-6">
                            <b-form-group class="custom-input" label="Nombre:">
                              <b-form-input type="text" v-model="form.user.entregaNombre" size="sm" placeholder=""/>
                            </b-form-group>
                          </div>

                          <div class="col-md-6">
                            <b-form-group class="custom-input" label="Teléfono:">
                              <b-form-input type="text" v-model="form.user.entregaTel" size="sm" placeholder="" minlength="10" maxlength="10"/>
                            </b-form-group>
                          </div>
                        </div>

                        <div class="row mx-0 no-gutters">
                          <div class="col-12">

                            <div class="box-location-type">
                              <fieldset class="form-group custom-input mb-1">
                                <legend class="col-form-label pt-0">Tipo de lugar</legend>
                              </fieldset>

                              <b-nav tabs fill>
                                <b-nav-item :active="form.user.tipoLugar == 1" @click="form.user.tipoLugar = 1"><span>Casa</span></b-nav-item>
                                <b-nav-item class="depa" :active="form.user.tipoLugar == 2" @click="form.user.tipoLugar = 2"><span>Departamento</span></b-nav-item>
                                <b-nav-item :active="form.user.tipoLugar == 3" @click="form.user.tipoLugar = 3"><span>Negocio</span></b-nav-item>
                              </b-nav>

                              <div class="box-nav-body" v-if="form.user.tipoLugar">
                                <div class="row" v-if="form.user.tipoLugar == 1">
                                  <div class="col-lg-6 mb-2">
                                    <b-form-group class="custom-input mb-0">
                                      <b-form-checkbox
                                        v-model="form.user.coto"
                                        name="checkbox-c1"
                                        :value="true"
                                        :unchecked-value="null">
                                        Esta dentro de un Coto & Fraccionamiento
                                      </b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group class="mt-2 custom-input" label="Nombre del Coto o Fraccionamiento:" v-if="form.user.coto">
                                      <b-form-input type="text" v-model="form.user.nombreCoto" size="sm" placeholder="" required/>
                                    </b-form-group>
                                  </div>

                                  <div class="col-lg-6 mb-2">
                                    <b-form-group class="custom-input mb-0">
                                      <b-form-checkbox
                                        v-model="form.user.planta"
                                        name="checkbox-c1"
                                        :value="true"
                                        :unchecked-value="null">
                                        El producto comprado estará en segunda planta.
                                      </b-form-checkbox>
                                    </b-form-group>
                                  </div>
                                </div>

                                <div class="row" v-if="form.user.tipoLugar == 2">
                                  <div class="col-lg-6 mb-2">
                                    <b-form-group class="custom-input mb-0">
                                      <b-form-checkbox
                                        v-model="form.user.coto"
                                        name="checkbox-c1"
                                        :value="true"
                                        :unchecked-value="null">
                                        Esta dentro de un Coto & Fraccionamiento
                                      </b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group class="mt-2 custom-input" label="Nombre del Coto o Fraccionamiento:" v-if="form.user.coto">
                                      <b-form-input type="text" v-model="form.user.nombreCoto" size="sm" placeholder="" required/>
                                    </b-form-group>
                                  </div>

                                  <div class="col-lg-6 mb-2">
                                    <b-form-group class="custom-input mb-0">
                                      <b-form-checkbox
                                        v-model="form.user.elevador"
                                        name="checkbox-c1"
                                        :value="true"
                                        :unchecked-value="null">
                                        Se puede utilizar elevador
                                      </b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group class="mt-2 custom-input" label="Piso del departamento:">
                                      <b-form-input type="text" v-model="form.user.pisoDepa" size="sm" placeholder="" required/>
                                    </b-form-group>
                                  </div>
                                </div>

                                <div class="row" v-if="form.user.tipoLugar == 3">
                                  <div class="col-lg-6 mb-2">
                                    <b-form-group class="custom-input mb-0">
                                      <b-form-checkbox
                                        v-model="form.user.parqueIndustrial"
                                        name="checkbox-c1"
                                        :value="true"
                                        :unchecked-value="null">
                                        Esta dentro de parque industrial
                                      </b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group class="mt-2 custom-input" label="Nombre del parque industrial:" v-if="form.user.parqueIndustrial">
                                      <b-form-input type="text" v-model="form.user.nombreParqueInd" size="sm" placeholder="" required/>
                                    </b-form-group>
                                  </div>

                                  <div class="col-lg-6 mb-2">
                                    <b-form-group class="custom-input mb-0">
                                      <b-form-checkbox
                                        v-model="form.user.torre"
                                        name="checkbox-c1"
                                        :value="true"
                                        :unchecked-value="null">
                                        Es una torre de oficinas
                                      </b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group class="mt-2 custom-input" label="Nombre de edificio o torre:" v-if="form.user.torre">
                                      <b-form-input type="text" v-model="form.user.nombreEdificioTorre" size="sm" placeholder="" required/>
                                    </b-form-group>
                                  </div>

                                  <div class="col-lg-6 mt-2 mb-2">
                                    <b-form-group class="custom-input mb-0">
                                      <b-form-checkbox
                                        v-model="form.user.bodega"
                                        name="checkbox-c1"
                                        :value="true"
                                        :unchecked-value="null">
                                        Es una bodega
                                      </b-form-checkbox>
                                    </b-form-group>

                                    <b-form-group class="mt-2 custom-input" label="El producto comprado estara en segunda planta:">
                                      <b-form-input type="text" v-model="form.user.segundaPlanta" size="sm" placeholder="" required/>
                                    </b-form-group>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-7 col-lg-6">
                            <b-form-group class="custom-input" label="Calle:" label-for="i-calle">
                              <b-form-input id="i-calle" type="text" v-model="form.user.street" size="sm" placeholder=""/>
                            </b-form-group>
                          </div>

                          <div class="col-md pl-md-3">
                            <b-form-group class="custom-input" label="Numero interior:" label-for="i-num">
                              <b-form-input id="i-num" type="text" v-model="form.user.num_int" size="sm"/>
                            </b-form-group>
                          </div>

                          <div class="col-md-12 col-lg-12">
                            <b-form-group class="custom-input" label="Referencia:" label-for="i-reference">
                              <b-form-textarea id="i-reference" v-model="form.user.reference" placeholder="Ej: Color exterior, cantidad de pisos, etc." rows="3" max-rows="3" size="sm"></b-form-textarea>
                            </b-form-group>
                          </div>
                        </div>

                        <div class="row mx-0 no-gutters">
                          <div class="col-md-8">
                            <b-form-group class="custom-input" label="Colonia:" label-for="i-colonia">
                              <b-form-input id="i-colonia" type="text" v-model="form.user.neighborhood" size="sm" placeholder="" />
                            </b-form-group>
                          </div>

                          <div class="col-md pl-md-3">
                            <b-form-group class="custom-input" label="Código postal:" label-for="i-cp">
                              <b-form-input id="i-cp" type="text" v-model="form.user.zipcode" size="sm" maxlength="5" placeholder="" />
                            </b-form-group>
                          </div>
                        </div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
              </div>

              <div v-if="form.tipoEntrega == 'recoger'">
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Sucursal:">
                      <b-form-select v-model="form.user.sucursal" :options="offices"  value-field="id" text-field="name" size="sm" required></b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-6"></div>

                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Día de recolección:" label-for="i-date">
                      <b-form-datepicker v-model="form.user.entregaDia" :min="minDate" :date-disabled-fn="dateDisabled" :date-format-options="dateFormOpts" v-bind="datepickerOpts" placeholder="Seleccione una opción" size="sm" required></b-form-datepicker>
                    </b-form-group>
                  </div>

                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Hora de recolección:" label-for="i-hour">
                      <b-form-select v-model="form.user.entregaHora" :options="timeOptions" size="sm"></b-form-select>
                    </b-form-group>
                  </div>

                  <div class="row px-3" v-if="form.user.sucursal">
                    <div class="col-12">
                      <b-form-group class="custom-input" label="Sucursal:">
                        <p>
                          Salto San Juan Bosco
                        </p>
                      </b-form-group>
                    </div>

                    <div class="col-md-6">
                      <b-form-group class="custom-input" label="Dirección:">
                        <p>
                          Industria No.1543 Sector Libertad<br />
                          C.P. 44380 Zona Olimpica<br />
                          Guadalajara Jal.
                        </p>
                      </b-form-group>
                    </div>

                    <div class="col-md-6">
                      <b-form-group class="custom-input" label="Horario:">
                        <p>
                          Lunes a sabado de 10:00 a 20:00<br />
                          Domingo de 10:00 a 18:00
                        </p>
                      </b-form-group>
                    </div>

                    <div class="col-md-6">
                      <b-form-group class="custom-input" label="Teléfono:">
                        <p>
                          33 2153 4078
                        </p>
                      </b-form-group>
                    </div>

                    <div class="col-md-6">
                      <b-form-group class="custom-input" label="Mapa:">
                        <p>
                          <a target="_blank" href="https://maps.google.com/maps?ll=20.675713,-103.31383&z=15&t=m&hl=es-419&gl=MX&mapclient=embed&cid=147736521435972913"><i class="fa-solid fa-location-dot"></i> Mostrar en mapa</a>
                        </p>
                      </b-form-group>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="mb-2 col-12">
                    <h6 class="f-w-600">Contacto de quien recolecta</h6>
                    <p>
                      Si desea que alguien mas reciba su pedido, agreguelo aquí.
                    </p>
                  </div>
                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Nombre:">
                      <b-form-input type="text" v-model="form.user.entregaNombre" size="sm" placeholder=""/>
                    </b-form-group>
                  </div>

                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Teléfono:">
                      <b-form-input type="text" v-model="form.user.entregaTel" size="sm" placeholder="" minlength="10" maxlength="10"/>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <!-- <div class="row mx-0 no-gutters py-3">
                  <b-form-checkbox id="checkbox-1" v-model="facturacion.factura" name="checkbox-1"><strong class="f-w-500" style="position:relative; top: 2px;">Solicitar Factura</strong></b-form-checkbox>
              </div> -->
              <template v-if="facturacion.factura">
                <h6 class="mt-4">Datos de facturación</h6>
                <hr class="c-hr"/>

                <div class="row mx-0 no-gutters">
                  <div class="col-md-6 pr-md-3">
                    <b-form-group class="custom-input" label="Nombre o razón social:" label-for="f-nombre">
                      <b-form-input id="f-nombre" type="text" v-model="facturacion.nombre" size="sm" required/>
                    </b-form-group>
                  </div>

                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Rfc:" label-for="f-rfc">
                      <b-form-input id="f-rfc" type="text" v-model="facturacion.rfc" size="sm" required/>
                    </b-form-group>
                  </div>
                </div>

                <div class="row mx-0 no-gutters">
                  <div class="col-md-12 col-lg-12">
                    <b-form-group class="custom-input" label="Cuenta con la que se realizará el pago:" label-for="f-cuente">
                      <b-form-input id="f-cuenta" type="text" v-model="facturacion.cuenta" size="sm" required />
                    </b-form-group>
                  </div>
                </div>

                <div class="row mx-0 no-gutters">

                  <div class="col-md-6 pr-md-3">
                    <b-form-group class="custom-input" label="Tipo de comprobante:" label-for="fcomprobante">
                      <b-form-select id="fcomprobante" v-model="facturacion.tipo" size="sm" required>
                        <option :value="null">Seleccione una opcion</option>
                        <option value="Adquisición de nercancias">Adquisición de mercancias</option>
                        <option value="Devoluciones, descuentos o bonificaciones">Devoluciones, descuentos o bonificaciones</option>
                        <option value="Gastos en general">Gastos en general</option>
                        <option value="Construcciones">Construcciones</option>
                        <option value="Mobilario y equipo de oficina por inversiones">Mobilario y equipo de oficina por inversiones</option>
                        <option value="Equipo de transporte">Equipo de transporte</option>
                        <option value="Equipo de computo y accesorios">Equipo de computo y accesorios</option>
                        <option value="Dados, troquetes, moldes, matrices y herramental">Dados, troquetes, moldes, matrices y herramental</option>
                        <option value="Comunicaciones telefónicas">Comunicaciones telefónicas</option>
                        <option value="Comunicaciones satelitales">Comunicaciones satelitales</option>
                        <option value="Otra maquinaria y equipo">Otra maquinaria y equipo</option>
                        <option value="Por definir">Por definir</option>
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-6">
                    <b-form-group class="custom-input" label="Correo" label-for="fcorreo">
                      <b-form-input id="fcorreo" v-model="facturacion.correo" size="sm" type="email" required></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row mx-0 no-gutters">
                      <div class="col-md-6 pr-md-3">
                        <b-form-group class="custom-input" label="Dirección" label-for="fdireccion">
                          <b-form-input id="fdireccion" v-model="facturacion.direccion" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-3 pr-md-3">
                        <b-form-group class="custom-input" label="Numero Exterior" label-for="fext">
                          <b-form-input id="fext" v-model="facturacion.no_ext" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-3">
                        <b-form-group class="custom-input" label="Numero Interior" label-for="fint">
                          <b-form-input id="fint" v-model="facturacion.no_int" size="sm" type="text"></b-form-input>
                        </b-form-group>
                      </div>
                  </div>
                  <div class="row mx-0 no-gutters">
                      <div class="col-md-6 pr-md-3">
                        <b-form-group class="custom-input" label="Telefono" label-for="iftelefono">
                          <b-form-input id="ftelefono" v-model="facturacion.telefono" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group class="custom-input" label="Codigo Postal" label-for="fcp">
                          <b-form-input id="fcp" v-model="facturacion.cp" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                  </div>
                  <div class="row mx-0 no-gutters">

                      <div class="col-md-4 pr-md-3">
                        <b-form-group class="custom-input" label="Estado" label-for="festado">
                          <b-form-select id="festado" v-model="facturacion.estado" :options="states"  value-field="id" text-field="name" size="sm" required @change="getFciudades"></b-form-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-4 pr-md-3">
                        <b-form-group class="custom-input" label="Ciudad" label-for="fciudad">
                            <b-form-select id="festado" v-model="facturacion.ciudad" :options="fciudades" value-field="id" text-field="name" size="sm" required></b-form-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <b-form-group class="custom-input" label="Colonia" label-for="fcolonia">
                          <b-form-input id="fcolonia" v-model="facturacion.colonia" size="sm" type="text" required></b-form-input>
                        </b-form-group>
                      </div>
                  </div>
              </template>

            </form>
            <!-- Paso 2, fin  -->

            <!-- Paso 3 (pago) -->
            <div class="col-12 py-4 px-4 user-data" v-if="$route.params.step == 'pago'">

              <h5 class="f-w-700">Resumen del pedido</h5>
              <hr class="c-hr" />

              <div class="px-3 mb-5 box-summary">
                <div class="row row-products" v-for="(product, index) in form.products">
                  <div class="col col-image">
                    <img :src="product.imageUrl">
                  </div>

                  <div class="col col-info">
                    <h5 class="mb-1 txt-name">
                      <span>{{ product.name }}</span>
                    </h5>

                    <div class="row mx-0 mb-1 align-items-center">
                      <div class="d-flex">
                        <h6 class="txt-sm text-muted mr-2">SKU: {{ product.sku }}</h6>
                        <h6 class="txt-sm text-muted mr-2" v-if="product.color"> Color: {{ product.color }} </h6>
                        <h6 class="txt-sm text-muted" v-if="product.size"> Talla: {{ product.size }} </h6>
                      </div>
                      <!-- <div class="d-flex">
                        <div class="box-color-opt opt-sm">
                          <a class="color" title="Color x" v-bind:style="{ backgroundColor: '#e63003' }">{{ product.color }}</a>
                          <a class="color" :title="product.color">{{ product.color }}</a>
                          <a class="color" :title="product.size">{{ product.size }}</a>
                        </div>
                      </div> -->
                    </div>

                    <div class="box-shipping-type">
                      <span class="icon i-1"></span>
                      <span class="text">Llega mañana</span>
                      <!-- <span class="icon i-2"></span>
                      <span class="text">Llega en 4 días</span> -->
                      <!-- <span class="icon i-3"></span>
                      <span class="text">Llega en 1 semana</span> -->
                    </div>
                  </div>

                  <div class="col col-price">
                    <p>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }} MXN</p>
                  </div>
                  <div class="col col-num">
                    <div class="box-quantity">1</div>
                  </div>
                </div>
              </div>

              <h5 class="f-w-700">Seleccione una forma de pago</h5>
              <hr class="c-hr" />

              <!-- <div class="mb-2 custom-input _p text-center">
                <label class="col-form-label pt-0">Seleccione una forma de pago:</label>
              </div> -->

              <div class="row pt-2 mb-4 justify-content-center payment-options-container">
                <div class="col col-box" @click="changePaymentMe('tarjetas')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'tarjetas' }">
                    <h6>Tarjeta de crédito o débito</h6>
                    <p class="icons">
                      <img class="tdc-tdd" src="public/images/shared/amex-visa-mastercard.svg">
                    </p>
                  </div>
                </div>

                <div class="col col-box" @click="changePaymentMe('transferencia')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'transferencia' }">
                    <h6>Deposito o transferencia<br />bancaria</h6>
                    <p class="icons">
                      <!-- <i class="fas fa-money-check-alt"></i>
                      <i class="fas fa-money-bill"></i> -->

                      <img src="public/images/shared/bank.svg">
                    </p>
                  </div>
                </div>

                <div class="col col-box" @click="changePaymentMe('sucursal')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'sucursal' }">
                    <h6>Pagar en la<br />sucursal</h6>
                    <p class="icons">
                      <img src="public/images/logo.svg">
                    </p>
                  </div>
                </div>

                <div class="col col-box" @click="changePaymentMe('linkPago')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'linkPago' }">
                    <h6>Compartir link<br />de pago</h6>
                    <p class="icons">
                      <!-- <i class="fas fa-money-check-alt"></i>
                      <i class="fas fa-money-bill"></i> -->

                      <img src="public/images/shared/link.svg">
                    </p>
                  </div>
                </div>

                <div class="col col-box" @click="changePaymentMe('tarjetas')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'paypal' }">
                    <h6>Paypal</h6>
                    <p class="icons">
                      <!-- <i class="fas fa-money-check-alt"></i>
                      <i class="fas fa-money-bill"></i> -->

                      <img class="paypal" src="public/images/shared/paypal.png">
                    </p>
                  </div>
                </div>

                <div class="col col-box" @click="changePaymentMe('tarjetas')">
                  <div class="box" v-bind:class="{ active: form.tipoPago == 'mercadopago' }">
                    <h6>MercadoPago</h6>
                    <p class="icons">
                      <!-- <i class="fas fa-money-check-alt"></i>
                      <i class="fas fa-money-bill"></i> -->

                      <img class="ml" src="public/images/shared/mercadopago.webp">
                    </p>
                  </div>
                </div>
              </div>

              <!-- Pago 1, con tarjetas -->
              <div class="row mx-0 no-gutters" v-if="form.tipoPago == 'tarjetas'">
                <div class="col-12">
                  <h6 class="text-center f-w-700">Detalles de tarjeta</h6>

                  <div id="cardElement"></div>
                  <small class="form text text-muted" id="cardErrors" role="alert"></small>
                </div>

                <div class="col-12">
                  <div class="row mx-0 no-gutters">
                    <div class="col-12">
                      <b-form-group class="custom-input" label="Nombre en la tarjeta:">
                        <b-form-input type="text" v-model="form.user.cardname" size="sm" placeholder="" required/>
                      </b-form-group>
                    </div>

                    <div class="col-lg-12 col-xl-6 pr-xl-3">
                      <b-form-group class="custom-input" label="Numero en la tarjeta:">
                        <b-form-input type="number" v-model="form.user.cardnum" size="sm" required/>
                      </b-form-group>
                    </div>

                    <div class="col-6 col-xl-3 pr-3">
                      <b-form-group class="custom-input" label="Fecha de expiración:">
                        <b-form-input type="text" v-model="form.user.cardexp" size="sm" maxlength="5" pattern="[0-9]*" placeholder="MM/AA" inputmode="numeric" required/>
                      </b-form-group>
                    </div>

                    <div class="col-6 col-xl-3">
                      <b-form-group class="custom-input" label="CVV:">
                        <b-form-input type="number" v-model="form.user.cardcvv" size="sm" required/>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->

              <!-- Pago 2, con transferencia bancaria -->
              <div class="col-12 text-center" v-if="form.tipoPago == 'transferencia'">
                <!-- <img class="mb-1" :src="depositdata.imageUrl" width="100"> -->
                <p>{{depositdata.banco}}</p>
                <p>{{depositdata.beneficiario}}</p>
                <p><strong>Cuenta bancaria:</strong> HSBC México</p>
                <p><strong>Clabe Interbancaria:</strong> 4508400023520365</p>

                <hr />
                <p>
                  Favor de enviarnos el comprobante de pago al siguiente correo: <br />
                  <strong>pagos@ekardegas.mx</strong>
                </p>
              </div>
              <!--  -->

              <!-- Pago 3, con sucursal -->
              <div class="col-12" v-if="form.tipoPago == 'sucursal'">
                <div class="row justify-content-center">
                  <div class="col-lg-7">
                    <p class="text-center">Llegue a la sucursal <strong class="f-w-700">Salto San Juan Bosco</strong> para realizar el pago contra entrega.</p>
                  </div>

                  <div class="col-12">
                    <hr />
                  </div>

                  <div class="col-lg-7">
                    <h6 class="mt-2 f-w-700">Dirección</h6>
                    <p>
                      Industria No.1543 Sector Libertad C.P. 44380 Zona Olimpica Guadalajara Jal.
                    </p>

                    <h6 class="mt-2 f-w-700">Horario</h6>
                    <p>
                      Lunes a sabado de 10:00 a 20:00<br />
                      Domingo de 10:00 a 18:00
                    </p>

                    <h6 class="mt-2 f-w-700">Teléfono</h6>
                    <p>
                      <a href="tel:3321534078">33 2153 4078</a>
                    </p>
                  </div>
                </div>
              </div>
              <!--  -->

              <!-- Pago 4, Link de pago -->
              <div class="col-12 px-0 px-xl-3 text-center" v-if="form.tipoPago == 'linkPago'">
                <div class="box-share-link">
                  <p>
                    <success-component-v2></success-component-v2>
                  </p>

                  <h5 class="mt-4 f-w-700 text-center">Link de pago seguro generado correctamente</h5>

                  <p class="mt-2 text-center">
                    <img class="img-fluid" src="public/images/shared/amex-visa-mastercard.svg">
                  </p>

                  <div class="mt-3 box-link">
                    <div class="col-md-9 mx-auto">
                      <div role="group" class="input-group">
                        <input disabled id="inline-form-input-username" type="text" value="https://ekardegas.online/#/producto/5211501d454D4" class="form-control">
                        <div class="input-group-prepend"></div>
                        <button class="t-150 input-group-text" @click="copyLink('https://ekardegas.online/#/producto/55')">Copiar link</button>
                      </div>

                      <div class="row mt-3">
                        <div class="col-sm-6 my-2 pr-sm-2">
                          <ShareNetwork
                            network="email"
                            key="email"
                            url="https://ekardegas.online/#/producto/55"
                            title="EKAR DE GAS"
                            description="Esto es un ejemplo de descripción">
                            <a class="btn btn-s1"><i class="fa-solid fa-envelope mr-1"></i> Enviar por Correo</a>
                          </ShareNetwork>
                        </div>
                        <div class="col-sm-6 my-2 pl-sm-2">
                          <ShareNetwork
                            network="whatsapp"
                            key="whatsapp"
                            url="https://ekardegas.online/#/producto/55"
                            title="EKAR DE GAS"
                            description="Esto es un ejemplo de descripción">
                            <a class="btn btn-s1"><i class="fa-brands fa-whatsapp mr-1"></i> Enviar por WhatsApp</a>
                          </ShareNetwork>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
            </div>
            <!-- -->

            <!-- Paso 4 (finalizado) -->
            <div class="col-12 py-4 px-4 col-finish" v-if="$route.params.step == 'finalizado'">
              <p class="mb-3">
                <!-- <i class="far fa-check-circle icon-check"></i> -->
                <!-- <i class="fal fa-check-circle icon-check"></i> -->
                <success-component></success-component>
              </p>
              <h4 class="mb-3 f-w-600">¡Pedido realizado exitosamente!</h4>

              <h5>Número de pedido: <strong class="txt-orange">EKAR-001{{ modal.orderId }}</strong></h5>
              <h5>Por favor de enviar su comprobante de pago.</h5>

              <h6 class="mt-3" v-html="modal.msg"></h6>

              <p class="mt-2">
                <router-link to="/"><strong><i class="fas fa-home"></i> Ir al Inicio</strong></router-link>
              </p>
            </div>
        </div>

          <div class="row mx-0 py-3 px-lg-0 no-gutters row-total" v-if="$route.params.step != 'finalizado'">
            <div class="col-md-6 mb-3 mt-lg-0 text-center text-lg-left">
              <router-link class="btn btn-outline-danger btn-sm btn-action" :to="'/cart/' + form.prev" v-if="form.prev != null">Anterior</router-link>
              <router-link class="btn btn-outline-danger btn-sm btn-action" :to="'/cart/' + form.next" v-if="form.products.length && form.next && form.next != 'pago'">Siguiente</router-link>
              <input type="submit" form="form-cliente" class="btn btn-outline-danger btn-sm btn-action" value="Siguiente" v-if="form.next == 'pago'">
              <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'transferencia'" @click="pagar()" :disabled="desactivar || order_id">Confirmar pedido</button>
              <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'tarjetas'" @click="pagar()" :disabled="desactivar || order_id || form.payment_intent_id">Pagar</button>
              <button class="btn btn-danger btn-sm btn-action" v-if="form.paying && form.tipoPago == 'sucursal'" @click="pagar()" :disabled="desactivar || order_id || form.payment_intent_id">Recolectar en tienda</button>
            </div>

            <div class="col-md-6 text-right">
              <h6><span>Subtotal: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(subtotal) }}</h6>
              <h6><span>IVA: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(iva) }}</h6>
              <h6><span>Costo de envío: </span>
                <strong v-if="form.envio > 0">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(form.envio) }}</strong>
                <strong v-if="form.envio == 0"> Por Calcular</strong>
              </h6>
              <h6 v-if="form.envio > 0"><span>Tiempo de envío: </span> 3 a 5 días hábiles</h6>
              <h5><span>Total a pagar: </span> ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total) }}</h5>
            </div>
          </div>
        </div>
      </div>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
          <b-button class="btn btn-success" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/')">Regresar a Home <i class="color-white fas fa-home"></i></b-button>
        </div>
      </sweet-modal>

    </div>
  </div>
</template>

<script>
import successComponent from '../shared/success-component.vue';
export default {
  data(){
    return{
      form: {
        products: [
          { id: 1, imageUrl: 'public/images/pages/products/product-2.jpg', sku: 'SD3506', name: 'Batería gibson elite 131383.06r negro 6 piezas base induccion 2.5mm', price: 820, stock: 10, quantity: 1 },
          { id: 2, imageUrl: 'public/images/pages/products/product-3.jpg', sku: 'KBZ5S0', name: 'Estufa Koblenz eke505f piso inn 30pulgadas gris cubierta inox capelo', price: 9799, stock: 10529, quantity: 1 },
        ],

        user: {
          "email": '',
          "name": '',
          "lastname": '',
          "phone": '',
          "street": '',
          "num_ext": '',
          "num_int": '',
          "neighborhood":'',
          "zipcode": null,
          "state_id": null,
          "town_id": null,
          "reference": '',
          "sucursal": null,
          "entregaHora": null,
          "tipoLugar": null,
        },

        prev: '',
        next: '',
        paying: false,

        tipoPago: '',
        tipoEntrega: '',
        subtotal: 0,
        iva: 0,
        total: 0,
        envio: 0,
        payment_intent_id: null
      },

      facturacion:{
        required:false,
        nombre:'',
        rfc:'',
        tipo:null,
        cuenta:'',
        correo:'',
        direccion:'',
        no_ext:'',
        no_int:'',
        calles:'',
        telefono:'',
        cp:'',
        colonia:'',
        ciudad:null,
        estado:null,
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },

      userLogin: false,
      states: [
        { id: null, name: 'Seleccione un estado' },
        { id: 1, name: 'Ciudad de México' },
        { id: 2, name: 'Jalisco' },
        { id: 3, name: 'Zacatecas' },
      ],
      towns: [
        { id: null, name: 'Seleccione una ciudad' },
        { id: 1, name: 'Guadalajara' },
        { id: 2, name: 'Tonalá' },
        { id: 3, name: 'Zapopan' },
      ],
      offices: [
        { id: null, name: 'Seleccione una sucursal' },
        { id: 1, name: 'El Salto' },
        { id: 2, name: 'Salto San Juan Bosco' },
        { id: 3, name: 'Salto Olímpico' },
      ],
      fciudades: [],
      order_id: null,

      stripe: {},
      elements: {},
      cardElement: {},

      desactivar: false,
      depositdata:{},

      // == Variables para datepicker y timepicker ==
      minDate: null,
      dateFormOpts: { year: 'numeric', month: 'numeric', day: 'numeric' },
      datepickerOpts: {
        labelPrevYear: 'Año anterior',
        labelPrevMonth: 'Mes anterior',
        labelCurrentMonth: 'Mes actual',
        labelNextMonth: 'Mes siguiente',
        labelNextYear: 'Año siguiente',
        labelToday: 'Hoy',
        labelSelected: 'Fecha Seleccionada',
        labelNoDateSelected: 'No seleccionado',
        labelCalendar: 'Calendario',
        labelHelp: 'Use las teclas de movimiento para navegar'
      },
      timeOptions: [
        { value: null, text: 'Seleccione una opción' },
        { value: '1', text: '11:00 AM' },
        { value: '2', text: '12:00 PM' },
        { value: '3', text: '01:00 PM' },
        { value: '4', text: '02:00 PM' },
        { value: '5', text: '03:00 PM' },
        { value: '6', text: '04:00 PM' },
        { value: '7', text: '05:00 PM' },
        { value: '8', text: '06:00 PM' },
      ]
      // == ==
    }
  },

  computed: {
    subtotal() {
     var subtotal = this.form.products.reduce(function (subtotal, item) {
        return subtotal + (item.price * item.quantity)
      },0);

      return this.form.subtotal = subtotal - (subtotal * 0.16);
    },

    iva() {
     var subtotal = this.form.products.reduce(function (subtotal, item) {
        return subtotal + (item.price * item.quantity)
      },0);

      return this.form.iva = subtotal * 0.16;
    },

    total() {
      var subtotal = this.form.products.reduce(function (subtotal, item) {
        return subtotal +  (item.price  * item.quantity)
      },0);

      var grandTotal  = parseFloat(subtotal) + parseFloat(this.form.envio);
      return this.form.total = grandTotal;
    }
  },

  methods: {
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`)
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6
    },

    copyLink(value) {
      navigator.clipboard.writeText(value);
      // alert('Link copiado');
      alert('Link copiado correctamente');
    },

    changePaymentMe(tipo){
      this.form.tipoPago = tipo;
      if(tipo == "tarjetas"){
        setTimeout(this.loadStripe, 50);
      }
    },

    changeShipping(tipo) {
      this.form.tipoEntrega = tipo;
    },

    validar: async function(){
        if(this.form.products.length){
            this.$router.push({ path: '/cart/'+this.form.next });

        }else{
            alert('No hay productos en el carrito');
        }
    },

    pagar(){
      if(this.form.tipoPago == "transferencia"){
        this.desactivar = true;
        this.createOrder();
      }

      if(this.form.tipoPago == "tarjetas"){
        this.desactivar = true;
        this.payStripe();
      }

      if(this.form.tipoPago == "sucursal"){
        this.desactivar = true;
        this.$router.push("/cart/finalizado");
      }
    },

    getSteps(){
      var step = this.$route.params.step;
      this.form.paying = false;

      if(step == 'pago'){
            if(this.form.user.email && this.form.user.name && this.form.user.phone){
              this.form.prev = 'envio';
              this.form.next = null;
              this.form.paying = true;
              this.cotizador();
            }else{
                this.$router.push({ path: '/cart/envio' });
            }
      }
      else if(step == 'envio'){ // Paso 2
        this.form.prev = '';
        this.form.next = 'pago';
      }
      else{
        this.form.prev = null;

        if(this.form.products.length){
          this.form.next = 'envio';
        }
      }
    },

    getStates(){
      axios.get(tools.url('/api/states')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(){
      if(this.form.user.state_id){
        axios.get(tools.url('/api/towns/' + this.form.user.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },

    getFciudades(){
      axios.get(tools.url('/api/towns/' + this.facturacion.estado)).then((response)=>{
        this.fciudades = response.data;
      }).catch((error)=>{
        console.log(error);
      });
    },

    cotizador(){
      axios.post(tools.url('/api/cotizador'),{ products:this.form.products, zipcode:this.form.user.zipcode }).then((response)=>{
        if(response.data.total){
            this.form.envio = (parseFloat(response.data.total) * 1.16) * 1.038 + 3;
          }
      }).catch((error)=>{
        console.log(error);
      });
    },

    getInfo(){
      this.form.user = {
        //Datos personales
        id: this.$root.user.id,
        name: this.$root.user.name,
        lastname: this.$root.user.lastname,
        email: this.$root.user.email,
        phone: this.$root.user.phone,
        street: this.$root.user.address.street,
        num_ext: this.$root.user.address.num_ext,
        num_int: this.$root.user.address.num_int,
        neighborhood: this.$root.user.address.neighborhood,
        zipcode: this.$root.user.address.zipcode,
        state_id: this.$root.user.address.state_id,
        town_id: this.$root.user.address.town_id,
      };

      this.getTowns();
    },

    getCart: function(){
      var storage = localStorage.getItem('cart');
      if(storage != null){
        var products = JSON.parse(storage);
        axios.post(tools.url('/api/cart'),{ products:products, zipcode: this.form.user.zipcode}).then((response)=>{
          this.form.products = response.data.cart;
          this.localStorageCart();
          this.getSteps();

        }).catch((error)=>{
          console.log(error);
          this.getSteps();
          localStorage.removeItem('cart');
          this.$root.cartCount = 0;
        });
      }
    },

    removeCart: function(index) {
        this.$delete(this.form.products, index)
        this.updateCart();
    },

    updateCart(){
        this.localStorageCart();
        this.getCart();
        if(this.form.user.zipcode){
            this.cotizador();
        }
    },

    localStorageCart(){
        var cart = [];
        this.form.products.forEach(function(val, index, array){
          var info = { id:val.id, quantity: val.quantity };
          cart.push(info);
        });
        localStorage.setItem("cart",JSON.stringify(cart));
        this.$root.cartCount = cart.length;
    },

    createOrder: function(){
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Procesando, por favor espere...';
        this.$refs.modal.open();

        axios.post(tools.url('/api/order/store'),{ order:this.form, products:this.form.products, user:this.form.user, userLogin:this.$root.logged, factura:this.facturacion }).then((response)=>{
            if(response.data.type === "success"){
                this.$refs.modal.close();
                this.order_id = response.data.order_id;
                localStorage.removeItem('cart');
                this.$root.cartCount = 0;
                //mensaje
                this.modal.orderId = response.data.order_id;
                this.modal.icon = "success";
                this.modal.msg = null;
                this.modal.block = false;
                this.$router.push({ path: '/cart/finalizado' });

            }else{
                this.$refs.modal.close();
                alert(response.data.error);
            }
        }).catch((error)=>{
            this.$router.push({ path: "/cart/finalizado" });
            // console.log(error);
            // this.modal.icon="error";
            // this.modal.msg = "Fallo al realizar el pedido., Verifique su internet y recargue la página";
            // this.modal.block = false;
            // this.$refs.modal.open();
            // this.desactivar = false;
        });

    },

    loadStripe(){
      //sandbox key
      this.stripe = Stripe('pk_live_51JPuYkGpPyDXa7VmuhKBvNXo8CWLdLTRcunP7BVBJo8S8qkAlVPQf0VeQ5VGz01iHeW5ar09Wgala7CnmLXUsIoe007JST9kYN');
      this.elements = this.stripe.elements({locale: 'es'});
      this.cardElement = this.elements.create('card', {hidePostalCode: true});

      this.cardElement.mount("#cardElement");
      this.cardElement.addEventListener('change', ({ error }) => {
          const displayError = document.getElementById('cardErrors');
          if (error) {
            displayError.textContent = error.message;
          } else {
            displayError.textContent = '';
          }
      });
    },

    payStripe(){
      this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Validando el metodo de pago. por favor espere...';
      this.$refs.modal.open();

      this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardElement,
          billing_details: { name: this.form.user.name+' '+this.form.user.lastname, email: this.form.user.email }
        }).then((result)=>{
          if (result.error) {
            alert('Ocurrió un error con su tarjeta');
            this.$refs.modal.close();
            this.desactivar = false;
          } else {
            const data  = { payment_method_id: result.paymentMethod.id, total: this.form.total, name: this.form.name, email: this.form.email };
            axios.post(tools.url('/api/stripe/Installments'),data).then((response)=>{
                this.form.payment_intent_id = response.data.intent_id;
                this.pagarStripe();
              });
          }
      });
    },

    pagarStripe: function(){
      this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Generando su pedido por favor espere...';
      this.$refs.modal.open();

      axios.post(tools.url('/api/stripe/pay'),{  order:this.form, products:this.form.products, user:this.form.user, userLogin:this.$root.logged, factura:this.facturacion }).then((response)=>{
          if(response.data.type === "success"){
            this.order_id = response.data.order_id;
            localStorage.removeItem('cart');
            this.$root.cartCount = 0;
            //mensaje
            this.modal.icon = "success";
            this.modal.msg = "TU PEDIDO HA SIDO EXITOSO!<br/> Número de pedido: XI000"+response.data.order_id+".";
            this.modal.block = true;
            this.$refs.modal.open();

          }else{
            this.modal.icon = "error";
            this.modal.msg = response.data.message;
            this.modal.block = false;
            this.desactivar = false;
          }
      }).catch((error)=>{
        this.$refs.modal.close();
        alert("ERROR: No se pudo realizar el pedido.");
        this.desactivar = false;
      });
    },

    getDepositdata(){
      axios.get(tools.url('/api/depositdata')).then((response)=>{
        this.depositdata = response.data;
      }).catch((error)=>{

      });
    },

  },

  beforeMount(){
    // this.getStates();
    this.getCart();

    const now = new Date();
    this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    if(this.$root.logged){
      this.getInfo();
    }

    this.getSteps();
    this.getDepositdata();
  },

  watch: {
    'form.user.cardexp':function(){
      // Card number without dash (-)
        let realNumber = this.form.user.cardexp.replace(/\//gi, '')

        // Generate dashed number
        let dashedNumber = realNumber.match(/.{1,2}/g)

        // Replace the dashed number with the real one
        this.form.user.cardexp = dashedNumber.join('/')
    },

    '$route.params.step': function () {
      this.getSteps();
    },

    'form.user.state_id':function(val){
      if(val && !isNaN(val)){
        this.getTowns();
      }
    },

    /*'form.user.zipcode':function(val){
      if(val && !isNaN(val) && val.length == 5){
        this.cotizador();
      }
    },*/

    "$root.logged": function(v) {
      if(v == true){
        this.getInfo();
      }
    },
  },

  components: {
    successComponent
  },
}
</script>

<style>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
