<template lang="html">
  <header id="header">
    <div class="header-content">

      <!-- Payments -->
      <div class="box-buy">
        <div class="container d-flex align-items-center oversized-container">
          <div class="col col-lg-3 col-xl col-ml">
            <h5>Compra de forma segura y rápida</h5>
          </div>
          <div class="col col-lg-3 col-xl col-ssl">
            <img src="public/images/shared/shipping.svg">
            <span>Recibe en casa</span>
          </div>
          <div class="col-lg-7 col-logos">
            <span>Paga en línea con tu tarjeta de débito o crédito</span>
            <i class="fab fa-cc-mastercard icon"></i>
            <i class="fab fa-cc-visa icon"></i>
            <i class="fab fa-cc-amex icon"></i>
            <img class="img-icon" src="public/images/shared/mercadopago.svg">
          </div>
        </div>
      </div>
      <!--  -->

      <!-- Header menu -->
      <div class="header-menu">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container oversized-container">
            <b-navbar-brand to="/">
              <img src="public/images/logo.png">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"><i class="far fa-bars icon"></i></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <!-- <b-nav-item class="d-lg-none d-xl-inline-flex item-simple" to="/">Inicio</b-nav-item> -->
                <li class="nav-item item-simple">
                  <a class="nav-link" @click="isMaActive = !isMaActive">Productos <i class="fas fa-chevron-down submenu-icon"></i></a>
                </li>
                <!-- <b-nav-item class="item-simple" to="/promociones">Promociones</b-nav-item> -->
                <!-- <b-nav-item class="item-simple" to="/masnuevo">Lo + nuevo</b-nav-item> -->
                <!-- <b-nav-item class="item-simple" to="/sucursales">Sucursales</b-nav-item>
                <b-nav-item class="item-simple" to="/servicios">Servicios</b-nav-item>
                <b-nav-item class="item-simple" to="/nosotros">Nosotros</b-nav-item> -->
                <b-nav-item class="item-simple" to="/promociones">Promociones</b-nav-item>
                <b-nav-item class="item-simple item-icon" to="/contacto"><i class="fa-solid fa-envelope icon d-none d-lg-inline-block"></i> <span class="d-lg-none">Contacto</span></b-nav-item>
                <b-nav-item class="item-simple item-icon" to="/mis-favoritos"><i class="fa-solid fa-star icon d-none d-lg-inline-block"></i> <span class="d-lg-none">Mis favoritos</span></b-nav-item>
                <!-- <b-nav-item class="item-simple" to="/contacto">Contacto</b-nav-item> -->

                <b-nav-item class="d-lg-none item-simple" to="/login" v-if="!$root.logged">Accede a tu cuenta</b-nav-item>
                <b-nav-item class="d-lg-none item-simple" to="/registrarse" v-if="!$root.logged">Registrarse</b-nav-item>
                <b-nav-item class="d-lg-none item-simple" to="/usuario" v-if="$root.logged">
                  <div>
                    <i class="fa-solid fa-user"></i> Bienvenido <strong>{{$root.user.name}}</strong><br />
                    <span>Mi cuenta</span>
                  </div>
                </b-nav-item>
              </b-navbar-nav>

              <!-- Right aligned nav items -->
              <!-- <b-navbar-nav class="ml-auto">
                <b-nav-item class="item-sm" to="/nosotros">Sobre nosotros</b-nav-item>
                <b-nav-item class="item-sm" to="">Comparar</b-nav-item>
                <b-nav-item class="item-sm" to="/contacto">Contáctanos</b-nav-item>

                <li class="d-lg-none nav-item item-line">
                  <a class="nav-link"><hr class="my-0" /></a>
                </li>
                <b-nav-item class="d-lg-none item-simple" to="/login" v-if="!$root.logged"><i class="far fa-user"></i> Iniciar sesión</b-nav-item>
                <b-nav-item class="d-lg-none item-simple" to="/registrarse" v-if="$root.logged"><i class="fas fa-user"></i> Registrarse</b-nav-item>
                 <b-nav-item class="d-lg-none item-simple" to="/usuario" v-if="$root.logged"><i class="fas fa-users-cog"></i> Mi cuenta</b-nav-item>
              </b-navbar-nav> -->
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Header options  -->
      <div class="header-options">
        <!-- <b-navbar toggleable="lg" type="dark" variant="info"> -->
        <b-navbar type="dark" variant="info">
          <div class="container oversized-container">
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-form class="search-form" @submit="onSubmitSearch">
                  <b-form-input placeholder="Busca algun producto..." v-model="formSearch.keywords"></b-form-input>

                  <!-- <b-form-select v-model="formSearch.category" >
                    <b-form-select-option :value="null">Categorías</b-form-select-option>
                    <b-form-select-option v-for="(cat,indx) in categories" :key="indx" :value="cat.id">{{cat.name}}</b-form-select-option>
                  </b-form-select> -->

                  <b-button class="btn-search" type="submit"><span class="d-none d-sm-inline">Buscar</span><i class="fas fa-search d-sm-none"></i></b-button>

                  <!-- Autocomplete -->
                  <div class="bg-autocomplete" v-bind:class="{ active: isAutocompActive }" @click="isAutocompActive = false;"></div>
                  <div class="box-autocomplete" v-bind:class="{ active: isAutocompActive }">
                    <div class="box">
                      <div class="box-results">

                        <div class="d-block text-center">
                          <!-- <loading2 v-if="isAuComLoading"></loading2> -->
                        </div>

                        <router-link class="flex-center-xy row-product"
                          :to="'/producto/'+$root._converToURL(p.name, p.id)"
                          v-for="(p, rInx) in autocompProds"
                          v-if="autocompProds.length > 0"
                          :key="'rInx-'+rInx">
                          <div class="col img" v-bind:style="{ backgroundImage: 'url('+p.img+')' }"></div>
                          <div class="col txt">
                            <div class="bubble-discount">-20%</div>
                            <h6 class="mini-text">{{ p.brand }}</h6>
                            <h6 class="name">{{ p.name }}</h6>
                            <h6 class="price">
                              <span>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.price) }}</span>
                              <span class="p-discount">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(p.priceOld) }}</span>
                            </h6>
                          </div>
                        </router-link>

                        <router-link class="flex-center-xy trans-150 row-product all t-150" v-if="autocompProds.length > 0" :to="{ path: '/busqueda', query: {keywords:this.formSearch.keywords} }">
                          <div class="col txt text-center">Mostrar todos</div>
                        </router-link>

                        <a class="flex-center-xy row-product no-shad" v-if="autocompProds.length <= 0 && !isAuComLoading">
                          <div class="col txt text-center">Sin resultados</div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <!--  -->
                </b-nav-form>
              </b-navbar-nav>

              <b-navbar-nav class="ml-auto navbar-nav-options">
                <!-- <b-nav-item class="icon-item" to="/mis-favoritos"><img src="public/images/shared/ic-favorites.svg"></b-nav-item> -->
                <b-nav-item class="icon-item cart" to="/cart"><div><i class="fa-light fa-cart-shopping cart-icon"></i> <u>{{$root.cartCount}}</u></div></b-nav-item>
                <b-nav-item class="item-simple" to="/login" v-if="!$root.logged">Accede a tu cuenta</b-nav-item>
                <b-nav-item class="item-simple" to="/registrarse" v-if="!$root.logged">Registrarse</b-nav-item>
                 <b-nav-item class="item-simple my-account" to="/usuario" v-if="$root.logged">
                  <div>
                    Bienvenido <strong>{{$root.user.name}}</strong><br />
                    <span>Mi cuenta</span>
                  </div>
                  <div class="ic-profile"></div>
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

    </div>

    <!-- Category A -->
    <div class="t-250 products-menu menu-a" v-bind:class="{ active: isMaActive }">
      <div class="menu-bg" @click="isMaActive = false"></div>

      <div class="tablist" role="tablist">
        <div class="t-250 box-title" @click="isMaActive = false">
          <h4>Productos</h4>
          <!-- <br>
          <h5 v-on:click="$router.push('/productos');">Categorias</h5> -->
          <!-- <i class="fas fa-chevron-left icon"></i> -->
          <i class="fa-light fa-xmark icon"></i>
        </div>

        <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
          <b-card-header header-tag="header" role="tab">
            <a class="btn-toggle" @click="openSubcategoryMenu(category.name, category.subcategories)">{{ category.name }}</a>
          </b-card-header>
        </b-card>
      </div>
    </div>
    <!--  -->

    <!-- Subcategory B & child category C (sub-sub-category) -->
    <div class="t-250 products-menu menu-b-c" v-bind:class="{ active: isMbcActive }">
      <div class="menu-bg" @click="isMaActive = false; isMbcActive = false;"></div>

      <div class="tablist" role="tablist">
        <div class="t-250 box-title" @click="isMbcActive = false">
          <h4>{{ subcategoryName }}</h4>
          <!-- <br>
          <h5 v-on:click="$router.push('/productos');">Categorias</h5> -->
          <!-- <i class="fas fa-chevron-left icon"></i> -->
          <i class="fa-light fa-chevron-left icon"></i>
        </div>

        <b-card no-body class="mb-1" v-for="(subcategory, subcatIdx) in subcategories" :key="'subcatIdx-'+subcatIdx">
          <b-card-header header-tag="header" role="tab">
            <div v-if="subcategory.childcategories" class="btn-toggle">
              <router-link :to="'/categoria/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
              <span class="fake-toggle" v-b-toggle="'accordion-'+subcatIdx"></span>
            </div>
            <router-link v-else class="btn-toggle" :to="'/categoria/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
          </b-card-header>

          <b-collapse v-if="subcategory.childcategories" :id="'accordion-'+subcatIdx" :visible="(subcatIdx == 0) ? true : false" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <ul class="ul-sub-options">
                <li v-for="(childcategory, ccatIdx) in subcategory.childcategories" :key="'ccatIdx-'+ccatIdx">
                  <router-link class="t-250" :to="'/subcategoria/'+$root._converToURL(childcategory.name, childcategory.id)">{{ childcategory.name }}</router-link>
                </li>
              </ul>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <!--  -->

  </header>
</template>

<script>
export default {
  data(){
    return{
      isMaActive: false,
      isMbcActive: false,

      isAutocompActive: false,
      isAuComLoading: true,
      autocompProds: [
        { id: 1, img: 'public/images/pages/products/product-2.jpg', brand: "Gibson", price: "850.00", priceOld: "1399", name: 'Batería gibson elite 131383.06r negro 6 piezas base induccion 2.5mm' },
        { id: 2, img: 'public/images/pages/products/product-1.jpg', brand: "T-FAL", price: "530.00", priceOld: "800", name: 'Licuadora T-fal ln283bmx 550w gris 2vel vidrio 1.25lt' },
      ],

      categories: [
        { id: 1, name: 'Utensilios',
          subcategories: [
            { id: 1, name: 'Preparación',
              childcategories: [
                { id: 1, name: 'Abrelatas' },
                { id: 2, name: 'Básculas' },
                { id: 3, name: 'Cernidor' },
                { id: 4, name: 'Cuchara' },
              ],
            },
            { id: 2, name: 'Baterías de cocina',
              childcategories: [
                { id: 1, name: 'Aluminio' },
                { id: 2, name: 'Acero' },
              ]
            },
          ]
        },
        { id: 2, name: 'Complementos para el hogar',
          subcategories: [
            { id: 1, name: 'Belleza',
              childcategories: [
                { id: 1, name: 'Mujer' },
                { id: 2, name: 'Hombre' },
              ]
            }
          ]
        },
        { id: 3, name: 'Electrónica',
          subcategories: [
            { id: 1, name: 'Video' },
            { id: 2, name: 'Audio' },
            { id: 3, name: 'Accesorios AV' },
          ]
        },
        { id: 4, name: 'Servicios' },
        { id: 5, name: 'Blancos' },
        { id: 6, name: 'Bases y colchones' },
        { id: 7, name: 'Enseres de cocina' },
        { id: 8, name: 'Estufas y campañas' },
        { id: 9, name: 'Refrigeración' },
        { id: 10, name: 'Lavadoras y secadoras' },
      ],

      subcategoryName: null,
      subcategories: [
        { id: null, name: null,
          childcategories: [
            { id: null, name: null },
          ]
        },
      ],

      formSearch: {
        keywords: null,
        category: null
      },
    }
  },

  methods: {
    openSubcategoryMenu(name, subcategory) {
      this.isMbcActive = true;
      this.subcategoryName = name;
      this.subcategories = subcategory;
    },

    onSubmitSearch(event) {
      event.preventDefault();

      this.$router.push({path: '/busqueda', query: {keywords:this.formSearch.keywords,category:this.formSearch.category}});
    },

    getCategories(){
			axios.get(tools.url("/api/categories")).then((response)=>{
          this.categories = response.data;
		    	this.$parent.inPetition=false;
		    }).catch((error)=>{
		    });
		},
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isAutocompActive = false;
      // this.autocompProds = [];
      this.isMaActive = false;
      this.isMbcActive = false;
    },

    'formSearch.keywords' (val, oldVal){
      // this.autocompProds = [];

      if(val.length >= 3){ // Buscar solo si tiene al menos 3 letras
        this.isAutocompActive = true;
      } else {
        this.isAutocompActive = false;
      }
    }
  },

  mounted(){
    // this.getCategories();
  }
}
</script>
