<template lang="html">
  <div class="placed-backg" id="about-us-page">

    <section class="main-section">
      <div class="placed-backg box-image"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-xl-6 col-image"></div>

          <div class="col-lg-7 col-xl-6 col-text">
            <h1 class="mb-2 title">Ekar de Gas</h1>

            <!-- <h5 class="subtitle"></h5> -->
            <p>
              El 13 de abril de 1973, cuando la industria del gas doméstico estaba en proceso de modernización, Ekar de Gas abre su primera sucursal, (en la esquina de los Ángeles y R. Michel, en el barrio de Analco) a un salto de rana de la central vieja de autobuses.
            </p>

            <p class="mt-3 text-justify">
              El nombre de Ekar de Gas proviene de la contracción de palabras “Equipos de Carburación de Gas”, como lo son las parrillas, estufas y boiler. Aparatos que en su momento eran lo último en tecnología y sustituirían la leña que se usaba en los hogares.
            </p>

            <p class="mt-3">
              Años más tarde, ante la creciente demanda, se fueron incorporando paulatinamente las categorías de electrodomésticos, línea blanca y colchones, de las marcas más reconocidas. La empresa amplió su cobertura con más saltos de rana en la ciudad de Guadalajara.
            </p>

            <p class="mt-3">
              A medida que se inauguraban nuevas tiendas, cada una adquirió un logotipo personalizado con una rana que hace alusión al nombre o su ubicación. Además, recordamos al reconocido locutor Rigoberto Sígala, quien se volvió icono e imagen de la marca. Actualmente, Ekar de Gas es una empresa icónica de la ciudad y de manera altruista patrocina y participa activamente en numerosos eventos culturales, juveniles y musicales.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section">
      <div class="container oversized-container">
        <div class="box-plans">
          <h2 class="mb-1 text-center title-1">Nosotros</h2>

          <!-- <p class="p-md text-center">
            Nuestro plan mundial de cultivo de cáñamo ahora está activo y produce miles de kilogramos de aceite de CBD al por mayor fresco durante todo el año.
          </p> -->

          <div class="row row-plans">
            <div class="col-lg-6 col-plan">
              <h6 class="title">MISIÓN</h6>
              <p>
                El propósito fundamental de Ekar de Gas es equipar los hogares con lo necesario para las tareas domésticas y rutinarias, respetando todas las normas de calidad y calidez humana, haciendo que lleguen de manera fácil y eficaz.
              </p>
            </div>

            <div class="col-lg-6 col-plan">
              <h6 class="title">VISIÓN</h6>
              <p>
                Llegar al mayor número de clientes, abarcar nuevos nichos de mercado en toda la región.
              </p>

              <p class="mt-3">
                Te gustaría ser parte de nuestro equipo?
              </p>
              <p class="mt-1">
                <router-link class="t-150" to="/contacto"><i class="fa-solid fa-envelope"></i> <u>Bolsa de trabajo</u></router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container values-section">
      <div class="row justify-content-center">
        <div class="col-12 col-title">
          <h2 class="title-st-1 c-secondary">Valores</h2>
        </div>

        <div class="col-md-6 col-lg-5 col-xl-4 col-value">
          <div class="box-icon">
            <img src="public/images/pages/about-us/icon-1.png">
          </div>

          <h5>Pasión por el hogar</h5>
          <p>
            Nuestra inspiración está en el deseo de que se cumpla el bienestar en los hogares tapatíos.
          </p>
        </div>

        <div class="col-md-6 col-lg-5 col-xl-4 col-value">
          <div class="box-icon">
            <img src="public/images/pages/about-us/icon-2.png">
          </div>

          <h5>Honestidad</h5>
          <p>
            Vendemos productos de calidad que puedan satisfacer las necesidades de nuestros clientes con expectativas realistas.
          </p>
        </div>

        <div class="col-md-6 col-lg-5 col-xl-4 col-value">
          <div class="box-icon">
            <img src="public/images/pages/about-us/icon-3.png">
          </div>

          <h5>Congruencia</h5>
          <p>
            Actuamos con lógica a la filosofía institucional al realizar nuestras actividades y relacionarnos con la sociedad logrando los objetivos sin sacrificar la confianza de clientes, proveedores y colaboradores.
          </p>
        </div>

        <div class="col-md-6 col-lg-5 col-xl-4 col-value">
          <div class="box-icon">
            <img src="public/images/pages/about-us/icon-4.png">
          </div>

          <h5>Respeto</h5>
          <p>
            Ekar de Gas es una empresa libre de prejuicios, ya que actuamos sin presuposiciones y con respeto a nuestros clientes, proveedores y colaboradores.
          </p>
        </div>

        <div class="col-md-6 col-lg-5 col-xl-4 col-value">
          <div class="box-icon">
            <img src="public/images/pages/about-us/icon-5.png">
          </div>

          <h5>Trabajo en equipo</h5>
          <p>
            Trabajamos en constante comunicación y participamos en conjunto dentro de nuestro entorno para tener resultados favorables y así poder satisfacer a nuestros clientes, proveedores y colaboradores.
          </p>
        </div>

        <div class="col-md-6 col-lg-5 col-xl-4 col-value">
          <div class="box-icon">
            <img src="public/images/pages/about-us/icon-6.png">
          </div>

          <h5>Visión al futuro</h5>
          <p>
            Conocemos el rumbo que debemos tomar con prioridades y objetivos claros. Visualizamos el camino que nos llevará al éxito.
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {

  data() {
    return {
      aboutus: [
        { bgUrl: 'public/images/pages/about-us/IMG_4895.JPG'},
        { bgUrl: 'public/images/pages/about-us/IMG_4899.JPG'},
        { bgUrl: 'public/images/pages/about-us/IMG_4913.JPG'},
        { bgUrl: 'public/images/pages/about-us/IMG_4935.JPG'},
        { bgUrl: 'public/images/pages/about-us/IMG_4941.JPG'},
        { bgUrl: 'public/images/pages/about-us/IMG_4917.JPG'},
        { bgUrl: 'public/images/pages/about-us/IMG_4933.JPG'},
      ],


      aboutOptions: {
        loop:true,
        slidesPerView: 1,
        spaceBetween: 10,
        autoplay: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
      },

      history:{

      }
    };
  },
  methods: {
      gethistories: function(){
        axios.get(tools.url('/empresaFront')).then(
          ({data}) =>{
            this.history=data[this.$i18n.locale];
          });
      }
  },
  mounted() {
      this.gethistories();
  }
}
</script>
<style>
  @media screen and (max-width: 768px) {
    .slide-text h1 {
        font-size: 3em !important;
    }
    #project-description-section .swiper-container {
        height: 500px !important;
    }
    .center-vertical-obj {
        padding: 1.5em 0 !important;
    }
}
</style>
