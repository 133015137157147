<template lang="html">
  <div class="cart-component">
    <b-form @submit="onSubmit">
      <!-- <div class="col col-quantity">
        <a class="btn-quantity minus" @click="changeQuantity('minus')"><i class="fa-regular fa-minus"></i></a>
        <b-form-input
          v-model="form.quantity"
          type="number"
          @paste.prevent
          @keypress="isNumber($event)"
          required
        ></b-form-input>
        <a class="btn-quantity plus" @click="changeQuantity('plus')"><i class="fa-regular fa-plus"></i></a>
      </div> -->

      <div class="col col-button">
        <b-button type="submit" class="btn-more"><i class="fa-light fa-cart-shopping"></i> Agregar</b-button>
      </div>
    </b-form>

    <b-modal modal-class="modal-added-product"  size="lg" ref="modal-added-prod" hide-footer title="" centered>
      <div class="px-lg-5 py-4">
        <p>
          <div class="sweet-modal-icon sweet-modal-success animate">
            <span class="sweet-modal-line sweet-modal-tip animateSuccessTip"></span>
            <span class="sweet-modal-line sweet-modal-long animateSuccessLong"></span>
            <div class="sweet-modal-placeholder"></div>
            <div class="sweet-modal-fix"></div>
          </div>
        </p>
        <p class="mt-5">
          El producto <strong>{{ product.name }}</strong> se agregado al carrito
        </p>

        <p class="mt-3">
          <b-button class="btn btn-modal my-2 mx-2" slot="button" v-on:click.prevent="$refs['modal-added-prod'].hide();">Agregar mas productos</b-button>
          <b-button class="btn btn-modal my-2 mx-2" slot="button" v-on:click.prevent="$refs['modal-added-prod'].hide(); $router.push('/cart')">Ir al carrito <i class="color-white fas fa-shopping-cart"></i></b-button>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['product'],

  data() {
    return {
      form: {
        quantity: 1
      },

      modal:{
        msg:'',
        icon:'',
        block:false
      },
    }
  },

  methods: {
    changeQuantity(a) {
      if(a == 'minus') {
        if(this.form.quantity > 1) {
          this.form.quantity--;
        }
      }
      else {
        this.form.quantity++;
      }
    },

    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      let letterCount = (this.form.quantity.toString()).replace(/\s+/g, '').length;

      if(letterCount <= 9) {
        // Permitir solo un punto
        // if ( charCode === 46 && (this.amount.indexOf('.') == this.amount.lastIndexOf('.')) ) {
        //   evt.preventDefault();
        // }
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
          // && charCode !== 46
        ) {
          evt.preventDefault();
        }
      } else {
        evt.preventDefault();
      }
    },

    onSubmit(event) {
      event.preventDefault();
      this.$refs['modal-added-prod'].show();
    },
  },
}
</script>
