<template lang="html">
  <div id="home-page">

    <!-- Banners  -->
    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.pc" :key="index">
          <a class="placed-backg" :href="banner.url ?  banner.url : '#'" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide  v-for="(banner, index) in banners.movil" :key="index">
           <a class="placed-backg" :href="banner.url ?  banner.url : '#'" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
             <img src="public/images/pages/home/banner-m.png">
           </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>
    <!--  -->

    <!-- Categorias -->
    <section class="categories-section">
      <div class="container oversized-container">
        <div class="col-12 col-sm-title">
          <!-- <h2 class="title">Categorías</h2> -->

          <!-- <p>
            Nuestra misión es, abastecer con nuestra gama de productos a nuestros clientes y futuros distribuidores.
          </p> -->
        </div>

        <div class="box-services">
          <swiper class="swiper" :options="categoriesOptions">
            <swiper-slide v-for="(s, shInx) in categories" :key="'shInx-'+shInx">
              <div class="col-12 px-0 box-category-sample-1">
                <router-link class="box" to="/categoria/1">
                  <img class="placed-backg fake-img" src="public/images/shared/empty.png" v-bind:style="{ backgroundImage: 'url('+s.imageUrl+')' }">

                  <div class="placed-backg box-text">
                    <h5>{{ s.name }}</h5>
                  </div>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Productos | Lo + visto -->
    <section class="container oversized-container mb-4 products-section" v-if="products_mostview.length">
      <div class="row">
        <div class="col-12 col-sm-title">
          <h2 class="title">Aprovecha nuestras ofertas</h2>

          <!-- <p>
            Nuestra misión es, abastecer con nuestra gama de productos a nuestros clientes y futuros distribuidores.
          </p> -->
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products_mostview" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <a class="outlined-box">
                  <router-link class="fake-link" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>
                    <div class="discount">-24%</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.brand }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <div class="b-prices">
                      <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                      <h6 class="price p-discount" v-if="product.priceOld"><span>$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.priceOld) }}</span></h6>
                    </div>
                  </div>

                  <cart-component :product="product"></cart-component>
                </a>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

     <!-- Multibanners -->
    <!-- <section class="container oversized-container multi-banners-section">
      <swiper class="swiper" :options="multibanersOptions">
        <swiper-slide v-for="(banner, bxhInx) in banner_middle" :key="'bxhInx-'+bxhInx">
          <div class="col-12 px-0 col-banner">
            <a :href="banner.url">
              <img :src="banner.imageUrl">
            </a>
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>  -->

    <!-- Simple banner -->
    <!-- <section class="simple-banner-section">
      <router-link class="img-desktop" to="">
        <img src="public/images/pages/home/banner-e-1.jpg">
      </router-link>

      <router-link class="img-mobile" to="">
        <img src="public/images/pages/home/banner-e-1-m.jpg">
      </router-link>
    </section> -->
    <!-- Simple banner -->

    <!-- Banner button -->
    <section class="banner-btn-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-4 col-text">
            <h2 class="title">La mejor tecnología</h2>

            <p>
              Lo mejor en tecnología, a los mejores precios, facil y rápido en línea.
            </p>

            <p class="mt-4">
              <router-link class="t-150 btn-more" to="">VER PRODUCTOS</router-link>
            </p>
          </div>

          <div class="col-lg-6 col-image">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/banner-product-1.jpg)' }">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Productos | Populares -->
    <section class="mb-4 products-section" style="background: #f5f6f9;">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-sm-title">
            <h2 class="title">Más populares</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12 px-0 col-products">
            <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
              <swiper-slide v-for="(product, index) in products_popular" :key="index">
                <div class="col-12 px-0 sample-product shad-prod">
                  <a class="outlined-box">
                    <router-link class="fake-link" :to="'/producto/'+$root._converToURL(product.name, product.id)"></router-link>

                    <div class="box-image">
                      <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                      <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                        <img src="public/images/shared/empty.png" >
                      </div>
                    </div>

                    <div class="box-shot-descr">
                      <h6 class="brand">{{ product.sku }}</h6>
                      <h6 class="name">{{ product.name }}</h6>
                      <h6 class="brand">{{ product.brand }}</h6>
                      <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                      <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                    </div>

                    <cart-component :product="product"></cart-component>
                  </a>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Customers -->
    <!-- <div class="customers-section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-title">
            <h2 class="title">Nuestros clientes</h2>
          </div>

          <div class="col-lg-4 col-logo">
            <img src="public/images/pages/about-us/mercedes-benz.png">
          </div>

          <div class="col-lg-4 col-logo">
            <img src="public/images/pages/about-us/volvo.png">
          </div>

          <div class="col-lg-4 col-logo">
            <img src="public/images/pages/about-us/volkswagen.png">
          </div>
        </div>
      </div>
    </div> -->
    <!--  -->

    <!-- Blog -->
    <!-- <section class="container oversized-container pb-4 brands-section" v-if="blogs.length">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">Posts destacados</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(blog, baInx) in blogs" :key="'ba-'+baInx">
          <router-link class="box-article" :to="'/blog/'+$root._converToURL(blog.title, blog.id)">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
              <img src="public/images/pages/blog/empty.png">
            </div>

            <div class="box-descr">
              <h5>{{ blog.title }}</h5>
              <div class="descr" v-html="blog.body"></div>
            </div>
          </router-link>
        </div>
      </div>
    </section> -->
    <!--  -->

    <!-- Brands -->
    <section class="container oversized-container mb-3 brands-section">
      <div class="row">
        <div class="col-12 col-sm-title">
          <h2 class="title">Marcas</h2>
        </div>

        <div class="col-12 col-brands">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(brand, brhInx) in brands" :key="'brhInx-'+brhInx">
              <div class="box-brand">
                <!-- <router-link to=""> -->
                <a :href="brand.link">
                  <div class="inside">
                    <img :src="brand.imageUrl">
                  </div>
                </a>
                <!-- </router-link> -->
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Modal de imagen con publicidad -->
    <!-- <b-modal ref="modal-promo" size="lg" modal-class="modal-home-promo" centered hide-footer title="">
      <img :src="modal">
    </b-modal> -->
    <!--  -->

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners:{
        pc:[
          { id: 1, url: null, imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { id: 1, url: null, imageUrl: 'public/images/pages/home/banner-2.jpg' },
        ],
        movil:[
          { id: 1, url: null, imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { id: 1, url: null, imageUrl: 'public/images/pages/home/banner-2-m.jpg' },
        ]
      },

      brands: [
        { link: null, imageUrl: 'public/images/pages/home/brand-1.jpg' },
        { link: null, imageUrl: 'public/images/pages/home/brand-2.png' },
        { link: null, imageUrl: 'public/images/pages/home/brand-3.png' },
        { link: null, imageUrl: 'public/images/pages/home/brand-4.png' },
        { link: null, imageUrl: 'public/images/pages/home/brand-5.png' },
        { link: null, imageUrl: 'public/images/pages/home/brand-6.png' },
        { link: null, imageUrl: 'public/images/pages/home/brand-7.png' },
      ],

      categories: [
        { imageUrl: 'public/images/pages/products/category-1.jpg', name: 'Utensilios' },
        { imageUrl: 'public/images/pages/products/category-2.jpg', name: 'Complementos para el hogar' },
        { imageUrl: 'public/images/pages/products/category-3.jpg', name: 'Electrónica' },
        { imageUrl: 'public/images/pages/products/category-4.jpg', name: 'Blancos' },
        { imageUrl: 'public/images/pages/products/category-5.jpg', name: 'Bases y colchones' },
        { imageUrl: 'public/images/pages/products/category-6.jpg', name: 'Refrigeración' },
      ],

      banner_middle:[],
      products_mostview:[
        {
          "id": 5,
          "brand": "T-FAL",
          "name": "Licuadora T-fal ln283bmx 550w gris 2vel vidrio 1.25lt",
          "price": "539",
          "priceOld": "800",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-1.jpg"
        },
        {
          "id": 2,
          "brand": "Gibson",
          "name": "Batería gibson elite 131383.06r negro 6 piezas base induccion 2.5mm",
          "price": "850.00",
          "priceOld": "1399",
          "stock": 2,
          "imageUrl": "public/images/pages/products/product-2.jpg"
        },
        {
          "id": 3,
          "brand": "Koblenz",
          "name": "Estufa Koblenz eke505f piso inn 30pulgadas gris cubierta inox capelo",
          "price": "9799",
          "priceOld": "10529",
          "stock": 3,
          "imageUrl": "public/images/pages/products/product-3.jpg"
        },
        {
          "id": 4,
          "brand": "Wendy",
          "name": "Colchon Wendy burgos suave individual beige no vuelta",
          "price": "2979",
          "priceOld": "3",
          "stock": 4,
          "imageUrl": "public/images/pages/products/product-4.jpg"
        },
        {
          "id": 5,
          "brand": "Mabe",
          "name": "Refrigerador Mabe rme360fgmrq0 14pies dark silver despachador",
          "price": "12099",
          "priceOld": "13499",
          "stock": 1,
          "imageUrl": "public/images/pages/products/product-5.jpg"
        },
        {
          "id": 6,
          "brand": "America",
          "name": "Colchon America tiffany firme matrimonial gris/negro no vuelta colchoneta europea refuerzo perimetra",
          "price": "9109",
          "priceOld": null,
          "stock": 1,
          "imageUrl": "public/images/pages/products/product-6.jpg"
        },
      ],
      products_popular: [
        {
          "id": 5,
          "brand": "Mabe",
          "name": "Refrigerador Mabe rme360fgmrq0 14pies dark silver despachador",
          "price": "12099",
          "priceOld": "13499",
          "stock": 1,
          "imageUrl": "public/images/pages/products/product-5.jpg"
        },
        {
          "id": 4,
          "brand": "Wendy",
          "name": "Colchon Wendy burgos suave individual beige no vuelta",
          "price": "2979",
          "priceOld": "3",
          "stock": 4,
          "imageUrl": "public/images/pages/products/product-4.jpg"
        },
        {
          "id": 3,
          "brand": "Koblenz",
          "name": "Estufa Koblenz eke505f piso inn 30pulgadas gris cubierta inox capelo",
          "price": "9799",
          "priceOld": "10529",
          "stock": 3,
          "imageUrl": "public/images/pages/products/product-3.jpg"
        },
        {
          "id": 2,
          "brand": "Gibson",
          "name": "Batería gibson elite 131383.06r negro 6 piezas base induccion 2.5mm",
          "price": "850.00",
          "priceOld": "1399",
          "stock": 2,
          "imageUrl": "public/images/pages/products/product-2.jpg"
        },
        {
          "id": 5,
          "brand": "T-FAL",
          "name": "Licuadora T-fal ln283bmx 550w gris 2vel vidrio 1.25lt",
          "price": "539",
          "priceOld": "800",
          "stock": 9,
          "imageUrl": "public/images/pages/products/product-1.jpg"
        },
      ],

      /* == Carousel options == */
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        },

        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
      },

      productsOption: {
        loop: false,
        slidesPerView: 5,
        spaceBetween: 10,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1599: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 10,
            slidesPerView: 3
          },
          766: {
            spaceBetween: 10,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        },
        autoplay: {
          delay: 5000, // Cambia el tiempo de retardo según lo que necesites
          disableOnInteraction: false
        },
        effect: 'slide', // Puedes cambiarlo a 'fade', 'cube', 'coverflow', etc.
        speed: 600 // La velocidad de la transición en milisegundos
      },

      brandsOptions: {
        loop: true,
        slidesPerView: 5,
        spaceBetween: 20,

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          992: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 2,
          },
        }
      },

      categoriesOptions: {
        slidesPerView: 4,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1600: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },

      multibanersOptions: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1400: {
            slidesPerView: 4
          },
          992: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        },
      }
      // == ==
    }
  },

  methods: {
    getHome: function(){

      axios.get(tools.url('/api/home')).then(({data}) =>{
        this.banners = data.banners;
        this.products_mostview = data.mostview;
        this.products_popular = data.popular;

        this.banner_middle = data.banners.pc;
      });
    },
  },

  mounted(){
    this.getHome();
  }
}
</script>
