<template lang="html">
  <div id="job-detail-page">

    <section class="container main-section">
      <h2 class="title-st-1">Bolsa de trabajo</h2>

      <div class="row">
        <!-- Description -->
        <div class="col-12 col-main-info">
          <div class="content">
            <div class="box-top">
              <div class="col-6 col-md col-info">
                <h5 class="m-title">Salario</h5>
                <h5 class="res">$8,495</h5>
              </div>

              <div class="col-6 col-md col-info">
                <h5 class="m-title">Departamento</h5>
                <h5 class="res">Ventas</h5>
              </div>

              <div class="col-6 col-md col-info">
                <h5 class="m-title">Horario</h5>
                <h5 class="res">Diurno</h5>
              </div>

              <div class="col-6 col-md col-info">
                <h5 class="m-title">Ubicación</h5>
                <h5 class="res">Zapopan, Jalisco</h5>
              </div>
            </div>

            <div class="box-description">
              <h5 class="m-title">Descripción del puesto</h5>
              <div>
                <p>
                  Somos una empresa tapatía con 50 de años de experiencia, dedicados a la compra y venta de electrodomesticos y linea blanca trabajando en colaboracion con las mejores marcas del mercado.
                </p>

                <br />
                <p>
                  Te invitamos a formar parte de nuestro equipo, a partir del primer día contaras con todas las prestaciones de ley , cotización al IMSS, INFONAVIT, Vacaciones conforme a la ley, prima vacacional, prima dominical, pago de horas extras y feriados conforme a la ley. Además Caja de ahorro, apoyo en gastos funerarios, uniformes sin costo, descuentos en productos, convenios de descuento con otras empresas. Capacitación y desarrollo. Solicitamos:
                </p>
              </div>

              <h5 class="mt-3 m-title">Ofrecemos</h5>
              <ul class="check-list">
                <li>Sueldo base + Bonos + Comisiones + Incentivos por desempeño y cumplimiento de meta</li>
                <li>Pago Semanal.</li>
              </ul>

              <br />
              <h5 class="m-title">Actividades</h5>
              <ul class="check-list">
                <li>Atención al cliente</li>
                <li>Labor de Venta</li>
                <li>Limpieza y acomodo de área de trabajo</li>
                <li>Recepción, acomodo y entrega de mercancía</li>
              </ul>

              <br />
              <h5 class="m-title">Requisitos</h5>
              <ul class="check-list">
                <li>A partir de secundaria terminada</li>
                <li>Experiencia no indispensable nosotros te capacitamos</li>
                <li>Gusto por la atención al cliente</li>
              </ul>

              <br />
              <h5 class="m-title">Horario</h5>
              <ul class="check-list">
                <li>Lunes a domingo</li>
                <li>Un día de descanso</li>
                <li>Requerimos disponibilidad para rolar turno una semana cubres de 10:00-19:00 y la siguiente de 11:00-20:00</li>
              </ul>

              <br />
              <h5 class="m-title">Zona de trabajo:</h5>
              <div>
                Sucursal Plaza del Sol Av. Mariano Otero 2795, Rinconada del Sol, 45055, Zapopan, Jalisco, México.
              </div>

              <br />
              <h5 class="m-title">Tags</h5>
              <div class="box-bubbles">
                <div class="bubble">Salario: $7,000.00</div>
                <div class="bubble">Ubicación: Zapopan, Jalisco</div>
              </div>

              <div class="mt-4">
                <a class="btn btn-send">Postularse</a>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
