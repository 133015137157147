<template lang="html">
  <div class="container" id="text-page">
    <h1 class="mb-3 page-title txt-black">Políticas de entrega, cancelación y devolución</h1>

    <p class="mb-2">
      Con el fin de brindar un servicio de excelencia, oportuno y con calidad a nuestros clientes, se indican a continuación las POLÍTICAS para la venta en línea a través del portal www.ekardegas.mx que es un dominio propiedad de la empresa Ekar De Gas S.A de C.V.
    </p>

    <br />
    <h5>Envíos</h5>
    <ul>
      <li>
        Al contratar el servicio de reparto, el cliente indicará cuál día de la semana se le entregará su mercancía. De no encontrarse ese día para recibir la mercancía, tendrá que volver a pagar el servicio.
      </li>
      <li>
        La garantía de nuestro servicio de reparto es hasta el interior de las puertas de su casa. En el caso de ser un departamento, edificio, o de ser necesario cualquier tipo de maniobra para llevar la mercancía al interior del domicilio, está maniobra será responsabilidad del cliente.
      </li>
      <li>
        Todos los envíos de mercancía tienen un costo adicional al producto comprado, este puede variar dependiendo del punto de entrega y la fecha solicitada.
      </li>
      <li>
        La principal zona de cobertura cubre la mayoría de las localidades de Zapopan, Tlaquepaque, Tlajomulco, y enteramente los municipios de Tonalá y Guadalajara. Consulte los precios y tiempos de entrega de nuestro servicio de reparto foráneo.
      </li>
      <li>
        Nuestro compromiso de entrega tiene un margen de 6 horas.
      </li>
    </ul>

    <br />
    <p>
      Al recibir su compra, antes de firmar de conformidad, le recomendamos:
    </p>
    <ul>
      <li>
        En el caso de línea blanca, desempacar el producto y revisarlo.
      </li>
      <li>
        En el caso de colchones, revise sin desempacar la bolsa.
      </li>
      <li>
        Una vez recibido y firmado de conformidad, el cliente desiste de cualquier reclamo por daños cosméticos consecuencia del traslado y envío de la mercancía.
      </li>
      <li>
        Como servicio adicional de Ekar de gas ofrece servicio a domicilio (área de entrega limitada). Para mayor información del servicio, solicite la información a su ejecutivo de ventas o bien a los teléfonos de atención a clientes de que vienen en su recibo de compra.
      </li>
    </ul>

    <br />
    <h5>Garantías de equipos</h5>
    <ul>
      <li>
        Ekar De Gas no es responsable de fallas mecánicas en el producto, La garantía de fabricación será atendida por el fabricante, de ser necesario, comuníquese con ellos a los teléfonos de atención a clientes.
      </li>
      <li>
        Para información detallada de las garantías que aplica para los equipos comercializados por Ekar De Gas S.A de C.V revise la póliza incluida en el producto que le fue entregado.
      </li>
    </ul>

    <br />
    <h5>Devoluciones de producto</h5>
    <ul>
      <li>
        En caso de que al recibir el producto en su domicilio este no quepa empacado por su puerta, solicite un cambio de mercancía, puede hacer en nuestro número de atención a clientes.
      </li>
      <li>
        No se aceptan cancelaciones ni devoluciones de ningún tipo.
      </li>
    </ul>

    <br />
    <h5>Consideraciones adicionales</h5>
    <ul>
      <li>
        Toda la información presentada en este documento está sujeta a cambios sin previo aviso.
      </li>
      <li>
        En caso de requerir alguna aclaración adicional contacte a su ejecutivo de ventas.
      </li>
      <li>
        Ekar de Gas se reserva el derecho de modificar sus precios sin previo aviso en base a los cambios que, a su vez apliquen los fabricantes.
      </li>
      <li>
        Ekar de gas no es responsable por contingencias fuera de su control de servicio que pudieran afectar los productos u otras actividades relativas a la entrega de mercancía.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
}
</script>
