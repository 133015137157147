<template lang="html">
  <div class="container" id="text-page">
    <h1 class="mb-3 page-title txt-black">Aviso de privacidad</h1>

    <p>
      EKAR DE GAS S.A. de C.V. , en adelante “EKAR DE GAS” en cumplimiento al marco jurídico mexicano, con fundamento en los artículos 15 y 16 a la Ley Federal de Protección de Datos Personales en Posesión de Particulares, pone a su disposición este Aviso de Privacidad. EKAR DE GAS en cualquiera de sus comercios matriz o sucursales, publicaciones o servicios, de conformidad a su naturaleza y objeto social; es respetuosa del derecho fundamental de todo ser humano a la privacidad. EKAR DE GAS, en su relación con clientes, empleados, proveedores y terceros, trata datos personales de diversa índole para el cumplimiento de las obligaciones y respeto a sus derechos. EKAR DE GAS puede pedirle que proporcione información personal identificable, alguna de ella es opcional, otra es obligatoria. Su privacidad es importante para nosotros. Para proteger mejor su privacidad, esta declaración explica nuestras prácticas de manejo de su información, así como las decisiones que usted puede tomar en relación a la forma en que su información será recabada y cómo es que esa información es utilizada, protegida y eliminada.
    </p>

    <br />
    <h5>Recolección de Información Personal</h5>
    <p>
      “Información Personal” significa toda información identificable como dato personal quele sea solicitada por EKAR DE GAS: nombre, edad, estado civil, estado de salud, sexo,domicilio, código postal, número telefónico, fotografía, audio y/o video sobre supersona, imagen, dirección de correo electrónico, nivel escolar, RFC, firma autógrafa,entre otros que lleguen a tener ese tratamiento por las leyes vigentes.Los datos personales que recabamos tienen como finalidad en general proveer losservicios y productos que ha solicitado; notificarle sobre nuevos servicios o productosque tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios enlos mismos; elaborar estudios y programas que son necesarios para determinar hábitosde consumo; realizar evaluaciones periódicas de nuestros productos y servicios aefecto de mejorar la calidad de los mismos; evaluar la calidad del servicio quebrindamos, y en general, para dar cumplimiento a las obligaciones que hemoscontraído con usted,
    </p>

    <br />
    <h6>Y para ello requerimos los siguientes datos:</h6>
    <ul>
      <li>Nombre completo</li>
      <li>Edad</li>
      <li>Estado civil</li>
      <li>Sexo</li>
      <li>Teléfono fijo y/o celular</li>
      <li>Correo electrónico</li>
      <li>Domicilio</li>
      <li>Nivel de escolaridad</li>
      <li>RFC y/o CURP</li>
      <li>Nivel de ingreso</li>
      <li>Enfermedades, antecedentes médicos familiares</li>
      <li>Firma autógrafa y /o aceptación vía electrónica</li>
      <li>Audio de voz</li>
      <li>Fotografía</li>
      <li>Video</li>
    </ul>

    <br />
    <p>
      Si algún dato no fuera necesario, “EKAR DE GAS” no lo requerirá, pero aquellos quesean explícitamente solicitados deberán proporcionarse, ya que en caso de no contarcon esta información no estaríamos en posibilidad de dar cumplimiento a todas y cadauna de las obligaciones contraídas con usted.El hecho de que usted nos proporcione Información Personal significa que estáautorizando a EKAR DE GAS para usar dicha información de acuerdo con los términosde esta Política, la cual incluye su derecho a limitarnos el uso de esta información.Para las finalidades señaladas en el presente aviso de privacidad, podemos recabar susdatos personales de distintas formas: cuando usted nos los proporciona directamente;cuando visita nuestro sitio de Internet o utiliza nuestros servicios en línea, y cuandoobtenemos información a través de otras fuentes que están permitidas por la ley.
    </p>

    <br />
    <h5>Uso y Destino de los Datos Personales</h5>
    <p>
      Los datos personales que recabamos de forma directa, cuando usted mismo nos losproporciona por diversos medios, o tercera persona autorizada, se podrán usar segúnla circunstancia en que se encuentre el titular, de manera enunciativa, más nolimitativa, para:
    </p>

    <br />
    <h6>Servicios en general</h6>
    <ul>
      <li>Acceso a instalaciones.</li>
      <li>Administración.</li>
      <li>Análisis estadísticos y de mercado.</li>
      <li>Aseguradoras.</li>
      <li>Bolsa de trabajo.</li>
      <li>Compartir o transferir los datos con terceros relacionados al vínculo, entre eltitular y EKAR DE GAS.</li>
      <li>
        Conservación de registros para seguimiento a servicios, prestación de serviciosen el futuro y en general para dar seguimiento a cualquier relación contractual.
      </li>
      <li>Contratación, evaluación y desarrollo de personal.</li>
      <li>Contrataciones</li>
      <li>Elaboración de estadísticas e informes requeridos por las autoridades en México.</li>
      <li>Envió de publicidad selectiva, información promocional de ofertas, concursos,campañas y eventos.</li>
      <li>Estadísticas e Informes.</li>
      <li>Proceso de pagos, entre otras.</li>
      <li>Procesos de Gestión Administrativa.</li>
      <li>Procesos relacionados a encuestas y evaluaciones de servicios o productos.</li>
      <li>Promoción de servicios relacionados a EKAR DE GAS.</li>
      <li>Promoción y mercadeo de productos y servicios de EKAR DE GAS u otrasempresas pertenecientes al mismo grupo corporativo.</li>
      <li>Recolección y publicación de fotos en medios impresos y electrónicos de eventos. </li>
      <li>Registro de acceso, asistencia y préstamo de material.</li>
      <li>Registro de proveedores y sus derivados.</li>
      <li>Responsivas.</li>
      <li>Servicios del Portal electrónico, App o aplicación en aparatos móviles, Comercio Electrónico.</li>
      <li>Solicitud de Admisión o Inscripción.</li>
      <li>Solicitudes de Ingreso.</li>
      <li>Transferencia de datos tratados a terceros dentro y fuera del país, por lo que suinformación puede ser compartida con asesores, calificadores y reguladores dela empresa.</li>
      <li>Uso de plataformas interactivas</li>
      <li>Uso del Sitio Web.</li>
      <li>Validar y verificar la relación laboral con terceros acreditados.</li>
      <li>Identificar y verificar a los clientes y analizar su capacidad crediticia.</li>
      <li>Para la celebración del contrato de crédito.</li>
      <li>Informar el estatus del crédito y hacer recordatorios de adeudos.</li>
      <li>Para desarrollar nuevos productos y servicios.</li>
      <li>Con fines de comunicación o contacto.</li>
      <li>Ofertas de forma personalizada.</li>
      <li>Atención de cualquier consulta, duda, aclaración o queja.</li>
    </ul>

    <br />
    <h6>Recursos Humanos</h6>
    <p>
      Los datos personales que sean recabados por parte del Área de Recursos Humanos deEKAR DE GAS para cumplir con las obligaciones derivadas de la relación laboral, tienen por objeto:
    </p>
    <ul>
      <li>Administración de personal.</li>
      <li>Análisis y elaboración de estadísticas.</li>
      <li>Asegurar el cumplimiento de obligaciones de confidencialidad y otrasobligaciones laborales.</li>
      <li>Asignar claves y contraseñas</li>
      <li>Asignar herramientas de trabajo.</li>
      <li>Atención de cualquier consulta, duda, aclaración o queja.</li>
      <li>Auditorías internas.</li>
      <li>Bonos.</li>
      <li>Celebración del contrato individual de trabajo.</li>
      <li>Comunicación o contacto.</li>
      <li>Contactar a sus familiares, dependientes económicos y/o beneficiarios en caso de emergencia.</li>
      <li>Contratación de seguros.</li>
      <li>Creación de cuenta de correo electrónico laboral.</li>
      <li>Cumplimiento a obligaciones de carácter fiscal y laboral.</li>
      <li>Documentar y controlar la asignación de activos de cómputo y comunicaciones.</li>
      <li>Elaboración de directorio de la empresa.</li>
      <li>Estudios socio-económicos.</li>
      <li>Formar expedientes de empleados.</li>
      <li>Formar expedientes médicos de empleados.</li>
      <li>Identificar y verificar a los empleados o candidatos.</li>
      <li>Integración y actualización de expedientes.</li>
      <li>Otorgamiento de estímulos y reconocimientos.</li>
      <li>Pago de beneficios, salarios, administración de nómina.</li>
      <li>Pensiones.</li>
      <li>Prestaciones.</li>
      <li>Proceso de reclutamiento y selección.</li>
      <li>Reembolsos.</li>
      <li>Seguros.</li>
    </ul>

    <br />
    <p>
      Traslado de datos a instituciones, terceras personas, prestadores de servicios quefuesen necesarios en virtud de los servicios, proveedores de servicios de recreación,esparcimiento, cultura, deportivos, funerarios, crediticios, turismo, inmobiliario,asistencia médica, odontológica, automotriz en todas sus áreas.Verificar referencias personales y laborales.
    </p>

    <br />
    <h5>Responsable del tratamiento de datos personales</h5>
    <p>
      EKAR DE GAS S.A. de C.V. -EKAR DE GAS -; es responsable del tratamiento de sus datospersonales conforme a este aviso de privacidad y tiene su domicilio legal en Calz. JesúsGonzález Gallo 496, Col. San Carlos; C.P. 44460, Guadalajara, Jalisco. México. O bienpor medio electrónico a la siguiente dirección www.ekardegas.mx en la ventana queexiste e indica “AVISO DE PRIVACIDAD”
    </p>

    <br />
    <h6>Finalidades, tratamiento por terceros y transferencias de datos personales</h6>
    <p>
      EKAR DE GAS tiene dentro de su objeto social el mercadeo de productos de tercerosrelacionados con el hogar, oficina e industria dentro y fuera de la República Mexicana.EKAR DE GAS recaba datos personales, que pueden comprender datos personalessensibles, para su operación, cumplimiento de obligaciones, respeto a sus derechos yen general la prestación de servicios de mercadeo físico y electrónico, en ciertos casos,a visitantes, proveedores y terceros. La recolección y tratamiento de datos personalespor parte de EKAR DE GAS está sujeta a la Ley Federal de Protección de DatosPersonales en Posesión de Particulares y a la Política de Protección de Datos Personales.
    </p>

    <br />
    <h6>Cómo Usamos su Información</h6>
    <p>
      Nosotros no compartimos su información con compañías, empresas, asociaciones, queno estén relacionadas con EKAR DE GAS, excepto en la extensión necesaria paracompletar su transacción y, cuando la compartimos, está sujeta a acuerdos con esascompañías para tratarla como información confidencial, y en el entendido que nopuede ser usada para ningún otro propósito distinto a aquel relacionado con cumplir sufunción como tal. Sin embargo, en cualquier momento usted podrá optar por dejar deproporcionar ésta información.<br />
      <br />
      En ocasiones podremos contactar a terceras personas para administrar o analizar lainformación que recabamos, incluyendo Información Personal, con la finalidad deayudarnos a mejorar nuestros productos, servicios y nuestros sitios de Internet.<br />
      <br />
      Adicionalmente y en caso de que usted nos solicite algún producto o servicio,podríamos llegar a proporcionar su Información Personal a distribuidores o terceraspersonas con la finalidad de entregarle el producto o prestarle el servicio de que setrate o en su caso para que un tercero se ponga en contacto con usted con la finalidadde que éste lo ayude con su solicitud en caso de que nosotros no lo podamos hacer demanera directa. Estos terceros no tendrán autorización nuestra para utilizar suInformación Personal de otra manera para la cual fue proporcionada.<br />
      <br />
      Por política interna la “EKAR DE GAS” no transmite, ni vende Información Personal.<br />
      <br />
      Eventualmente podríamos llegar a complementar la información que usted nos hayaproporcionado con información que nos haya sido proporcionada por terceras partes.Hacemos esto tanto con fines de investigación, de salud, estadísticos, satisfacción delcliente, mercadotecnia con la finalidad de poder atender mejor sus requerimientos yofrecerle productos y servicios que cumplan con sus necesidades.<br />
      <br />
      Finalmente le informamos que en caso de que se nos requiera por ley o mandamientode autoridad competente, proporcionaremos su información personal según seestablezca por ley o por la autoridad, en cuyo caso la autoridad estará obligada amantener la confidencialidad y el acceso restringido a la misma con base en las leyes yreglamentos aplicables.<br />
      <br />
      Por otra parte, hacemos de su conocimiento que sus datos podrán ser transferidos aentidades del mismo grupo de interés de la Sociedad Anónima, nacionales oextranjeras, con el objetivo general de cumplir con las finalidades para las cuales haproporcionado sus datos.<br />
      <br />
      En caso de que no obtengamos su oposición expresa para que sus datos personalessean transferidos en la forma y términos antes descrita, entenderemos que haotorgado su consentimiento en forma tácita para ello.En caso de que no desee recibir mensajes promocionales de nuestra parte, puedeenviarnos su solicitud a Calz. Jesús González Gallo 496, Col. San Carlos; C.P. 44460,Guadalajara, Jalisco. México; o en su defecto a cada una de las sucursales, o al visitarnuestro portal www.ekardegas.mx en la ventana “Aviso de Privacidad”
    </p>

    <br />
    <h5>Datos personales sensibles</h5>
    <p>
      EKAR DE GAS recabará y tratará datos sensibles, relacionados con el estado de salud,antecedentes e historial clínico, información sobre modo de vida y otros datos necesarios o convenientes para los fines arriba señalados. Los datos personalessensibles -origen racial o étnico, estado de salud presente y futuro, informacióngenética, creencias religiosas, filosóficas y morales, afiliación sindical, opinionespolíticas, preferencia sexual- serán mantenidos y tratados con estricta seguridad yconfidencialidad para fines relacionados con la prestación de servicios de salud yconforme a este aviso de privacidad y la legislación, reglamentos y normativaaplicable.
    </p>

    <br />
    <h5>Transferencia</h5>
    <h6>Para el cumplimiento de sus fines, así como para la prestaciónde sus servicios, podrá:</h6>
    <ol>
      <li>
        Facilitar sus datos personales a terceros con quienes tenga contratados serviciospara el procesamiento de datos, para acreditaciones, garantías y certificaciones, pararealizar estudios socio-económicos, para que EKAR DE GAS ejerza sus derechos, asícomo para ofrecer servicios de EKAR DE GAS, de cualquier tipo.
      </li>
      <li>
        Transferir sus datos personales a otras personas, incluyendo sin limitar, institucionescrediticias y de cobranza en cuyo caso sólo proporcionará los datos personales quesean indispensables para la actividad o servicio específico que dichas personasrealizarán.
      </li>
    </ol>

    <br />
    <p>
      Los terceros que reciban datos personales para su tratamiento o a quienes se lestransfieran datos personales, sólo recibirán aquellos datos personales que requieranpara realizar sus labores.
    </p>

    <br />
    <h6>Videos, audio y fotografía</h6>
    <p>
      EKAR DE GAS graba (audios y videos) y toma fotografías de los eventos que realizatales como apertura de sucursales, eventos deportivos, culturales, recreativos, dedifusión, comercialización, entre otros. Si usted ingresa a cualquier inmueble enposesión o propiedad de EKAR DE GAS acepta que podrá ser grabado y/o fotografiado,consintiendo que EKAR DE GAS los podrá utilizar, distribuir o transmitir para efectosnoticiosos, de promoción y otras actividades según lo permitido por ley.
    </p>

    <br />
    <h6>Limitación de uso y divulgación de datos personales</h6>
    <p>
      Para limitar el uso de sus datos personales, favor de enviar notificación por escrito aEKAR DE GAS S.A. de C.V.; (“EKAR DE GAS”) Calz. Jesús González Gallo 496, Col. SanCarlos; C.P. 44460, Guadalajara, Jalisco. México. O bien por medio electrónico a lasiguiente dirección www.ekardegas.mx en la ventana “Aviso de Privacidad” en el quese señale la limitación al uso de sus datos deseada.
    </p>

    <br />
    <h6>Privacidad de menores e incapaces</h6>
    <p>
      “EKAR DE GAS” está comprometida a proteger la privacidad de la informaciónproporcionada en relación a menores de edad y hacer de sus servicios decomercialización de insumos una experiencia segura y cómoda para nuestros usuarios.Toda información recabada con relación a menores de edad, deberá ser proporcionadapor el padre, tutor o representante legal de los mismos.Nosotros no recabamos información personal directamente de niños menores de edad,excepto por nuestra página de internet, que puede contener información para ellosdirectamente como futuros clientes, uso de aplicaciones o juegos interactivos oeducación de compra.
    </p>

    <br />
    <h5>Servicio web e información adicional que podríamos recabar.</h5>
    <p>
      En relación al servicio Web, le informamos que contamos con el sistema habilitado deuso de Cookies y Web Beacons; las cookies son archivos de texto que son descargadosautomáticamente y almacenados en el disco duro del equipo de cómputo del usuario alnavegar en una página de Internet específica, que permiten recordar al servidor deInternet algunos datos sobre este usuario, entre ellos, sus preferencias para lavisualización de las páginas en ese servidor, nombre y contraseña.Por su parte, las web beacons son imágenes insertadas en una página de Internet ocorreo electrónico, que puede ser utilizado para monitorear el comportamiento de unvisitante, como almacenar información sobre la dirección IP del usuario, duración deltiempo de interacción en dicha página y el tipo de navegador utilizado, registro declientela, de proveedores, de foros de chat.
    </p>

    <br />
    <h6>Le informamos que utilizamos cookies y web beacons para obtener informaciónpersonal de usted, como la siguiente:</h6>
    <ul>
      <li>Su tipo de navegador y sistema operativo.</li>
      <li>Las páginas de Internet que visita.</li>
      <li>Los vínculos que sigue.</li>
      <li>La dirección IP.</li>
      <li>El sitio que visitó antes de entrar al nuestro.</li>
    </ul>

    <br />
    <p>
      Estas cookies y otras tecnologías pueden ser deshabilitadas. Para conocer cómohacerlo, consulte el siguiente vínculo o dirección electrónica www.ekardegas.mxEn caso de los servicios prestados por internet o alguna app o aplicación endispositivos portátiles, contamos con “Cookies” que son archivos o piezas deinformación que se almacenan por su buscador en el disco duro de su computadora.
    </p>

    <br />
    <p>
      La“EKAR DE GAS” podría usar “Cookies” para recabar información adicional sobre usteddurante su visita a nuestra página de Internet, así como de las áreas particulares quevisite en nuestra página y los productos o servicios que pudieran llegar a interesarle através de nuestra página. Recolectamos esta información con la finalidad de poderajustar nuestra página y nuestros productos y servicios a sus intereses y necesidades.
    </p>

    <br />
    <p>
      Las “Cookies” también podrían ser utilizadas para acelerar la velocidad de sus futurasactividades en nuestras páginas, por ejemplo una página puede reconocer que ustedya nos ha proporcionado información personal y podrá no solicitar la mismainformación por segunda vez.
    </p>

    <br />
    <p>
      La mayoría de los buscadores se encuentran programados para aceptar “Cookies”. Siusted prefiere, usted puede programar el suyo con la finalidad de rechazar “Cookies” ode alertarlo cuando las “Cookies” se estén enviando. La restricción de las Cookies almomento de entrar a una página de Internet podría resultar en la inhabilidad paravisitar ciertas áreas de nuestra página en internet o recibir información personalizadadurante su visita a la misma. Esto, con la finalidad de asegurarnos que nuestraspáginas de Internet representen la mejor experiencia para nuestros usuarios, y que sonuna fuente de información efectiva. Finalmente, algunas páginas que usted visitepodrán utilizar “píxel tags” (también conocidas como “clear gifs”) para recabarinformación en relación con como navegó en nuestras páginas. Esta información esutilizada para evaluar y demostrar cómo funcionan estas páginas de Internet.
    </p>

    <br />
    <p>
      El sitio puede contener hipervínculos hacia y desde otros sitios de Internet, incluyendootros distintos a los sitios de la “EKAR DE GAS”. Estos sitios pueden tener diferentes prácticas de privacidad a aquellos sitios que son aquí descritos. Toda vez que estadeclaración se aplica únicamente a este sitio, por favor recuerde leer las declaracionesde privacidad de los demás sitios que usted visite.
    </p>

    <br />
    <h5>Medios para ejercer derechos ARCO (acceso, rectificación, cancelación y oposición)</h5>
    <p>
      Para tener acceso a los datos personales que EKAR DE GAS posee, así como pararectificarlos en caso de que éstos sean inexactos o incompletos, o para cancelarlos uoponerse a su tratamiento para ciertos fines, favor de presentar una solicitud porescrito dirigida a EKAR DE GAS S.A. de C.V.; (“EKAR DE GAS”) Calz. Jesús GonzálezGallo 496, Col. San Carlos; C.P. 44460, Guadalajara, Jalisco. México. O bien por medioelectrónico a la siguiente dirección www.ekardegas.mx en la ventana “Aviso dePrivacidad” de lunes a viernes de 9:00 a 18:00 hrs. que contenga la siguiente información:
    </p>
    <ul>
      <li>
        Nombre del titular
      </li>
      <li>
        Domicilio de titular o dirección de correo electrónico para comunicar respuestaa solicitud
      </li>
      <li>
        Documentos que acrediten identidad o autorización para representarlo en la solicitud
      </li>
      <li>
        Descripción de datos personales sobre los que se pretende ejercer algúnderecho ARCO
      </li>
      <li>
        Referir cualquier otro dato o exhibir Cualquier otro elemento que permita lalocalización de los datos personales y atención a la solicitud
      </li>
    </ul>

    <br />
    <p>
      Para responder a su solicitud con la determinación adoptada, EKAR DE GAS tendrá unplazo de 20 días hábiles contados a partir de la fecha en que recibió la solicitud deacceso, rectificación, cancelación u oposición de sus datos personales pararesponderle. Si la solicitud es procedente, se hará efectiva dentro de los 15 días hábiles siguientes a la fecha en que se le haya comunicado la respuesta anterior. Estos plazospodrán ser ampliados por una sola vez y por un periodo igual, cuando lascircunstancias del caso lo ameriten. La entrega de información procederá previaacreditación de su identidad o de su representante legal, según corresponda.
    </p>

    <br />
    <p>
      Al proporcionar información a EKAR DE GAS por cualquier medio, usted confirma queestá de acuerdo con los términos de este Aviso de Privacidad y la Política de Protecciónde Datos Personales. Si usted no estuviera de acuerdo con cualquier término del Avisoo Política de Protección de Datos Personales, por favor, no proporcione dato personalalguno. Si decide no proporcionar a EKAR DE GAS ciertos datos personales, acepta laposibilidad de no tener acceso a las instalaciones, actividades u otros servicios deEKAR DE GAS.Si usted deseara revocar el consentimiento otorgado para el tratamiento de datospersonales por parte de EKAR DE GAS, lo deberá informar a ésta por escrito.
    </p>

    <br />
    <h5>Medios para revocar consentimiento</h5>
    <p>
      En cualquier momento puede solicitar la revocación del consentimiento otorgado aEKAR DE GAS para tratar sus datos personales enviando una solicitud por escritodirigida a EKAR DE GAS S.A. de C.V.; “EKAR DE GAS” en Calz. Jesús González Gallo496, Col. San Carlos; C.P. 44460, Guadalajara, Jalisco. México. O bien por medioelectrónico a la siguiente dirección www.ekardegas.mx en la ventana “Aviso dePrivacidad” de Lunes a Viernes de 9:00 a 18:00 hrs. en la que se detalle claramente losdatos respecto de los que revoca su consentimiento.
    </p>

    <br />
    <h6>Cambios en Nuestra Política y Notificación de Cambios al Aviso de Privacidad</h6>
    <p>
      EKAR DE GAS se reserva el derecho de modificar en cualquier momento para cumplircon actualizaciones legislativas, jurisprudenciales, políticas internas, nuevos requisitospara la prestación de servicios de EKAR DE GAS o cualquier otra causa a enteradiscreción de EKAR DE GAS, así como su Política de Protección de Datos Personales y/oeste Aviso de Privacidad a su sola discreción.<br />
      <br />
      De modificarse el Aviso de Privacidad, EKAR DE GAS pondrá públicamente en su sitioweb www.ekardegas.mx el nuevo Aviso de Privacidad, por lo cual lo invitamos a quevisite esta sección periódicamente con la finalidad de que permanezca informado decualquier cambio, en su caso platicar con el personal de EKAR DE GAS que le asiste conla finalidad de que se encuentren y permanezcan informados.<br />
      <br />
      Todo cambio sustancial a la política, a menos que éste se derive de una reforma almarco jurídico mexicano o a una orden de autoridad competente, se notificará a travésdel sitio web institucional con treinta días naturales de anticipación a que surta efectosla modificación correspondiente.<br />
      <br />
      El presente Aviso de Privacidad. En tal caso, las modificaciones estarán disponibles ennuestra página de Internet www.ekardegas.mx.mx
    </p>

    <br />
    <h5>Medios de seguridad</h5>
    <p>
      Tomamos las precauciones necesarias para mantener su información personal segura.Toda aquella información personalizada identificable, está sujeta a acceso restringidocon la finalidad de evitar acceso a la misma no autorizado, modificaciones o mal uso.<br />
      <br />
      Para proteger los datos personales que reciba EKAR DE GAS, ésta ha establecidomedidas de seguridad tanto administrativas y técnicas como físicas, según sea másapropiado de acuerdo al tipo de datos personales en cuestión y el tratamiento al queestán sujetos.<br />
      <br />
      Este Aviso de Privacidad está vigente desde el 1º de enero del 2013.<br />
      <br />
      Por la presente, una vez leído el presente Aviso de Privacidad de EKAR DE GAS S.A. deC.V., entendiendo todos los términos y condiciones, otorgo mi consentimiento al estarde acuerdo con los términos del presente.
    </p>
  </div>
</template>

<script>
export default {
}
</script>
