<template lang="html">
  <div class="loading-component-v1">
    <div class="holder">
      <div class="preloader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
