<template lang="html">
<div>
  <b-form @submit.prevent="onSubmit()">
    <h6>Mis direcciones</h6>
    <hr class="c-hr" />

    <b-form-group class="custom-input" label="Dirección*">
      <b-form-input type="text" v-model="form.direccion" size="sm" required placeholder="" />
    </b-form-group>

    <div class="row">
      <div class="col-sm-6">
        <b-form-group class="custom-input" label="Numero exterior*">
          <b-form-input type="number" v-model="form.numero" size="sm" required placeholder="" />
        </b-form-group>
      </div>

      <div class="col-sm-6">
        <b-form-group class="custom-input" label="Numero interior*">
          <b-form-input type="number" v-model="form.numeroInt" size="sm" placeholder="" />
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <b-form-group class="custom-input" label="Estado*">
          <b-form-select v-model="form.estado" size="sm" required>
            <b-form-select-option :value="null">Estado</b-form-select-option>
            <b-form-select-option value="a">Jalisco</b-form-select-option>
            <b-form-select-option value="b">Ciudad de México</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>

      <div class="col-sm-6">
        <b-form-group class="custom-input" label="Ciudad*">
          <b-form-select v-model="form.ciudad" size="sm" required>
            <b-form-select-option :value="null">Ciudad</b-form-select-option>
            <b-form-select-option value="a">Guadalajara</b-form-select-option>
            <b-form-select-option value="b">Zapopan</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>

      <div class="col-sm-6">
        <b-form-group class="custom-input" label="Codigo postal*">
          <b-form-input type="number" v-model="form.cp" size="sm" required placeholder="" />
        </b-form-group>
      </div>
    </div>

    <p class="mt-2">
      <b-button type="submit" class="btn-red btn-form" variant="primary" :disabled="disabled">Guardar cambios</b-button>
    </p>

    <h6 class="mt-5">Direcciones</h6>
    <hr class="c-hr" />

    <div v-if="$root.user.address">
      <div class="row mx-0 no-gutters">
        <div class="col-12 mb-3">
          <h6 class="f-w-600">Dirección completa:</h6>
          <p>
            Calle X No. 950 Int. 540
          </p>
        </div>

        <div class="col-6 mb-3">
          <h6 class="f-w-600">Estado:</h6>
          <p>
            Jalisco
          </p>
        </div>

        <div class="col-6 mb-3">
          <h6 class="f-w-600">Ciudad:</h6>
          <p>
            Guadalajara
          </p>
        </div>

        <div class="col-6">
          <h6 class="f-w-600">Código postal:</h6>
          <p>
            45000
          </p>
        </div>

        <div class="col-sm-6 py-2 align-self-center">
          <p class="text-center text-sm-right">
            <a class="text-danger" style="font-size: 0.80rem;"><i class="fa-solid fa-trash"></i> Borrar dirección</a>
          </p>
        </div>
        <div class="col-12">
          <hr />
        </div>
      </div>
    </div>

    <div v-else>
      <h6 class="f-w-600 text-secondary"><i class="fa-solid fa-empty-set"></i> No hay direcciones guardadas</h6>
    </div>
  </b-form>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        {{modal.msg}}
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
        </div>
    </sweet-modal>
</div>
</template>

<script>
export default {
  data(){
    return{
        states: [],
        towns: [],
        disabled: false,

        modal:{
          msg:'',
          icon:'',
          block:false,
        },

        form: {
          direccion: null,
          numero: null,
          numeroInt: null,
          estado: null,
          ciudad: null,
          cp: null,
        },
    }
  },

  watch:{
    '$root.user.customer.state_id':function(val){
      if(val && !isNaN(val)){
        //this.getTowns(val);
      }
    }
  },

  methods: {
    getStates(){
      axios.get(tools.url('/api/states')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(state_id){
        if(state_id){
            axios.get(tools.url('/api/towns/' + state_id)).then((response)=>{
              this.towns = response.data;
            }).catch((error)=>{
              console.log(error);
            });
        }else{
            this.towns = [];
        }
    },

    onSubmit(){
        var data = {
            id: this.$root.user.id,
            name: this.$root.user.name,
            lastname: this.$root.user.lastname,
            email: this.$root.user.email,
            phone: this.$root.user.phone,
            //customer
            street: this.$root.user.address.street,
            num_int: this.$root.user.address.num_int,
            num_ext: this.$root.user.address.num_ext,
            neighborhood: this.$root.user.address.neighborhood,
            zipcode: this.$root.user.address.zipcode,
            state_id: this.$root.user.address.state_id,
            town_id: this.$root.user.address.town_id,
        };

        this.disabled = true;

        axios.post(tools.url('/api/user/profile'), data).then((response)=>{
            if(response.data.status == 'success'){
                this.disabled = false;
                this.modal.icon = "success";
                this.modal.msg = response.data.msg;
                this.modal.block = false;
                this.$refs.modal.open();
            }else{
                this.disabled = false;
                this.modal.icon = "error";
                this.modal.msg = response.data.msg;
                this.modal.block = false;
                this.$refs.modal.open();
            }
        }).catch((error)=>{
            this.disabled = false;
            this.handleErrors(error);
        });
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }
        }
        else{
            console.log(errors);
            err="Error desconocido.";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;
        this.$refs.modal.open();

    },
  },

  beforeMount(){
    this.getStates();
  },

  mounted(){
    if(this.$root.user.address && this.$root.user.address.state_id){
      this.getTowns(this.$root.user.address.state_id);
    }
  }
}
</script>
